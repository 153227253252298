export class Client {
  id: number;
  name: string;
  dashboards: Dashboard[];
  logoFileName: string;
  rename?: boolean;
  saving?: boolean;
}

export interface Filter {
  question: Question;
  options: Option[];
}

export class LinkOptions {
  linkBaseLine: boolean;
  linkRange: boolean;

  constructor() {
    this.linkBaseLine = false;
    this.linkRange = false;
  }
}

export class Demographic {
  title: string;
  sources: Question[];
  linkOptions: LinkOptions;

  constructor() {
    this.sources = [];
    this.linkOptions = new LinkOptions();
  }
}

export class MyCaW {
  title: string;
  measurementSources: Question[];
  seriesQuestion: Question;
  seriesQuestionOptions: Option[];
  linkOptions: LinkOptions;
  requireFullMeasureResponse: boolean;

  constructor() {
    this.measurementSources = [];
    this.seriesQuestion = new Question();
    this.linkOptions = new LinkOptions();
  }
}

export class Summary {
  title: string;
  measurementSources: Question[];
  seriesQuestion: Question;
  seriesQuestionOptions: Option[];
  linkOptions: LinkOptions;
  requireFullMeasureResponse: boolean;
  displayAsterisks: boolean;
  displayDifferenceColumn: boolean;

  constructor() {
    this.measurementSources = [];
    this.seriesQuestion = new Question();
    this.seriesQuestionOptions = [];
    this.linkOptions = new LinkOptions();
    this.requireFullMeasureResponse = false;
    this.displayAsterisks = true;
    this.displayDifferenceColumn = true;
  }
}

export class Layout {
  tiles: Tile[];
  summary: Summary;
  demographics: Demographic[];
  mycaw: MyCaW[];

  constructor() {
    this.tiles = [];
    this.summary = new Summary();
    this.demographics = [];
    this.mycaw = [];
  }
}

export class DashboardSettings {
  palette?: any;
  filters?: Question[];
  adminFilters?: Filter[];
  link?: Question;
  fullRange?: DateRange;
}

export class Dashboard {
  id: number;
  name: string;
  client: Client;
  surveyId: string;
  surveyTitle: string;
  accessKey: string;
  isPasswordProtected: boolean;

  layouts: {
    draft: Layout;
    published: Layout;
  };

  filters: Filter[];
  meta: DashboardSettings;

  createdOn: any;
  updatedOn: any;
  publishedOn: any;
  lastUpdatedBy: any;

  constructor() {
    this.layouts = {
      draft: new Layout(),
      published: new Layout()
    };
    this.filters = [];
    this.meta = new DashboardSettings();
  }
}

// TILE MODELS
export enum TilesTypes {
  count = 'count',
  timeline = 'timeline',
  responses = 'responses',
  bar = 'bar',
  line = 'line',
  pie = 'pie',
  text = 'text'
}

export enum LegendPositions {
  top = 'Top',
  right = 'Right',
  bottom = 'Bottom',
  left = 'Left'
}

export enum DataLabelDisplayOptions {
  value = 'Number',
  percentage = 'Percentage'
}

export class Tile {
  id: string;
  type: TilesTypes;
  config: Config;
  cols: number;
  rows: number;
  y: number;
  x: number;
  lastRender: number;

  title?: string;
  description?: string;
  footnote?: string;
  editing?: {
    title: false;
    description: false;
    footnote: false;
  };
  loading?: {
    title: boolean;
    footer: boolean;
  };
  settings?: {
    [key: string]: any;
    title: boolean;
  };

  constructor(tile?: Tile) {
    if (tile) {
      this.id = tile.id;
      this.type = tile.type;
      this.config = tile.config;
      this.cols = tile.cols;
      this.rows = tile.rows;
      this.y = tile.y;
      this.x = tile.x;
      this.title = tile.title;
      this.description = tile.description;
      this.footnote = tile.footnote;
    }

    this.editing = {
      title: false,
      description: false,
      footnote: false
    };

    this.settings = {
      title: tile.settings && tile.settings.title ? tile.settings.title : false
    };

    this.loading = {
      title: false,
      footer: false
    };
  }
}

export class Option {
  id: number;
  internalId: number;
  title: string;
  value: string;
  summaryDisplay?: boolean;
}

// SURVEY RELATED MODELS
export class Question {
  id: number;
  internalId?: number;
  type?: string;
  title: string;
  displayTitle?: string;
  alternateTitle?: string;
  alias?: string;
  options?: Option[];
  subQuestions?: Question[];
  rename?: boolean;
  loading?: boolean;
  summaryDisplay?: boolean;
  denominator = 3;
}

export class Survey {
  id: number;
  type: string;
  status: string;
  team: string;
  title: string;
  modifiedOn: string;
  createdOn: string;
}

export interface Config {
  // showGridLinesX: boolean;
}

export class CountTileConfig implements Config {}

export class TextTileConfig implements Config {
  text: string;
}

// export class ResponsesTileConfig implements Config {
//   responses?: string[];
// }

export interface Response {
  id: number;
  text: string;
}

export class TextResponsesTileConfig implements Config {
  question: Question;
  responses: Response[];
}

export class ChartConfig implements Config {
  chartTitle?: string;
  showLegend?: boolean;
  legendPosition?: LegendPositions;
  dataLabelDisplayOption?: DataLabelDisplayOptions;
  showDataLabels?: boolean;
  dataLabelsDecimalPlaces?: any;
  colour?: string;
  colourPalette?: string[];
  showGridLinesX?: boolean;
  showGridLinesY?: boolean;
  suggestedMax?: string;

  sources?: Question[];
  sourcesGroupsOnly?: false;
  aggregateTitle?: string;
  groupBy?: Question;

  titleType?: string;
  charactersPerLine?: string;
  displayZeroSeries?: boolean;
  isVertical?: boolean;
  isGroupedByQuestion?: boolean;

  constructor() {
    this.sources = [];
    this.titleType = 'LtAlias';
    this.displayZeroSeries = false;
    this.isVertical = true;
    this.dataLabelsDecimalPlaces = 0;
    this.suggestedMax = '0';
  }
}

export class TimelineTileConfig extends ChartConfig {
  dates?: string;
}

export class BarTileConfig extends ChartConfig {
  showScoreBaselines?: boolean;

  constructor() {
    super();

    this.titleType = 'LtAlias';
    this.charactersPerLine = '20';
    this.displayZeroSeries = false;
    this.showScoreBaselines = false;
    this.showDataLabels = true;
    this.showLegend = true;
    this.legendPosition = LegendPositions.top;
    this.isVertical = false;
    this.dataLabelDisplayOption = DataLabelDisplayOptions.value;
  }
}

export class LineTileConfig extends ChartConfig {
  sources?: Question[];
  groupBy?: Question;
}

export enum ChartDirection {
  horizontal = 'horizontal',
  vertical = 'vertical'
}

// SUMMARY RELATED MODELS
export interface SummaryResponse {
  data: {
    [key: number]: {
      [key: number]: {
        all: {
          count: number;
          value: number;
        };
        range: {
          count: number;
          value: number;
        };
      };
    };
  };
}

export interface DemographicResponse {
  data: {
    [key: number]: {
      [key: number]: {
        CountAll: number;
      };
    };
  };
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface SummaryPayload {
  groupQuestion: Question;
  sources: Question[];
  dateRange: DateRange;
}

export interface DemographicPayload {
  sources: Question[];
  dateRange: DateRange;
}

export interface DashboardPassword {
  dashboardId: number;
  password: string;
}
