import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tile, ChartConfig } from '@models/dashboard';
import { SurveyService } from '@app/services/survey/survey.service';
​
import * as _ from 'lodash';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
​
@Component({
  selector: 'app-tile-pie-editor',
  templateUrl: './pie.component.editor.html',
  styleUrls: ['./pie.component.editor.scss']
})
export class TilePieEditorComponent implements OnInit {

  @Input() tile: Tile;
  @Output() update = new EventEmitter();

  public config: ChartConfig;
​
  ngOnInit() {
    this.config = new ChartConfig();
    this.config = this.tile.config;
  }

  ngOnChange() {
    this.preview();
  }

  preview() {
    this.tile = Object.assign({}, this.tile);

    this.update.emit(this.tile);
  }

}
