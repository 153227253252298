import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question, Option } from '@app/models/dashboard';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() question: Question;
  @Input() selected: Option[];
  @Output() update = new EventEmitter();

  open = true;
  active = true;

  constructor(public dashboardService: DashboardService) {}

  ngOnInit() {

    this.dashboardService.currentFilters.subscribe(filters => {
      const filter = filters.find(x => x.question.internalId === this.question.internalId);
      if (filter) {
        this.selected = this.question.options.filter(x => filter.options.find(y => y.internalId === x.internalId));
      } else {
        this.selected = null;
      }
    });

  }

  set(selected) {
    console.log('FilterComponent set');
    this.selected = selected;
    this.update.emit({
      question: this.question,
      options: this.selected
    });

  }

}
