import { OnInit, OnDestroy, Component, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup } from '@angular/forms';

import { SurveyService } from '@services/survey/survey.service';
import { UploadService } from '@services/upload/upload.service';
import { SignalRService } from '@services/signal-r/signal-r.service';

import { saveAs } from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-survey',
  templateUrl: './admin-survey.component.html',
  styleUrls: ['./admin-survey.component.scss']
})
export class AdminSurveyComponent implements OnInit, OnDestroy {

  form: FormGroup;
  fileData;
  selectedFile;

  error: string;
  importResponse = { status: '', message: '', filePath: '' };
  signalResponse = '';

  surveyId: string;
  survey;

  loading = {
    init: true,
    rebuild: false
  };

  rebuildingActive = false;

  downloading: boolean;

  console = [];

  progress = {
    page: null,
    totalPages: null,
    totalCount: null,
    value: null
  };

  exportTypes = [
    
    { value: 'respondent', title: 'Responses', description: 'Survey responses with raw answers and 0-100 scores', active: false },
    { value: 'respondent-scores-only', title: 'Response scores only', description: 'Survey responses with 0-100 scores and 0-100 summaries', active: false },
    { value: 'respondent-raw-only', title: 'Response values only', description: 'Survey responses with 0-3 scores and 0-12 summaries', active: false },
    { value: 'question-lookup', title: 'Survey question lookup', description: 'All surveys to all questions lookup', active: false },
    { value: 'full', title: 'Full', description: 'Each question response as a new row', active: false }
  ];

  selectedImportType: any;
  importTypes = [
    { value: 'questions', label: 'Questions' },
    { value: 'responses', label: 'Responses' }
  ];

  signalList: any[] = [];

  @ViewChild('file', { static: false }) file: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private surveyService: SurveyService,
    private signalRService: SignalRService
  ) {
    this.downloading = false; 

    this.route.params.subscribe(params => {
      this.surveyId = params.surveyId;
    });

    this.signalRService.getMessage(
      (message: any) => {
        this.signalResponse = message;
      }
    );

  }

  ngOnInit() {
    this.surveyService.getById(this.surveyId).subscribe(
      survey => {
        this.survey = survey;
        this.loading.init = false;
      }
    );

    this.selectedImportType = this.importTypes[0];

    this.subscribeToSignalRHub();
  }

  ngOnDestroy(): void {
    this.signalRService.disconnect();
  }

  open(e) {
    e.stopPropagation();
    this.file.nativeElement.click();
  }

  subscribeToSignalRHub(){
    this.signalRService.signalReceived.subscribe((signal: any) => {
      console.log(signal);
      this.signalList.push(signal);
      this.signalResponse = signal.message;

      this.progress.page = signal.page;
      this.progress.totalPages = signal.totalPages;
      this.progress.totalCount = signal.totalCount;

      if (signal.page && signal.totalPages && signal.totalCount) {
        this.progress.value = Math.round((signal.page / signal.totalPages) * 100);
      }

      let msg = '';
      if (signal.message === 'Loading responses') {
        msg = signal.message + '...' + this.progress.value + '%';
      } else {
        msg = signal.message;
      }

      const d = new Date();
      this.console.push({
        message: msg,
        timestamp: d.toUTCString()
      });

      if (signal.surveyId === 0 && signal.message === 'Finished updating responses') {
        this.loading.rebuild = false;
        this.progress.value = 0;
      }
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0] as File;
      this.fileData = file;
    }

    this.selectedFile = event.target.files[0].name;

  }

  import(file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);

    this.surveyService.uploadAsCSV(this.surveyId, formData, this.selectedImportType.value).subscribe(
      res => {
        this.importResponse = res;
      },
      err => (this.error = err)
    );
  }

  rebuild() {

    this.loading.rebuild = true;
    this.rebuildingActive = true;

    this.surveyService.reloadSurveyData(this.surveyId).subscribe(
      res => {
        // triggers the survey rebuild. Messaging handled by rsync hub subscription.
      },
      err => (this.error = err)
    );
  }

  export(type) {
    if (type.value === 'question-lookup') {
      type.active = true;
      this.surveyService.downloadQuestionLookup().subscribe(
        data => {
           saveAs(data, 'survey-question-lookup-' + moment().format('YYYY-MM-DD') + '.csv');
           type.active = false;
        }
      );
    }else if (type.value === 'full') {
      type.active = true;
      this.surveyService.downloadResponsesAsCSV(this.surveyId).subscribe(
        data => {
           saveAs(data, 'download-' + this.surveyId + '-' + type.value + '-' + moment().format('YYYY-MM-DD') + '.csv');
           type.active = false;
        }
      );
    } else {
      type.active = true;
      this.surveyService.downloadResponsesByRespondentAsCSV(this.surveyId, type.value).subscribe(
        data => {
           saveAs(data, 'download-' + this.surveyId + '-' + type.value + '-' + moment().format('YYYY-MM-DD') + '.csv');
           type.active = false;
        }
      );
    }
  }

}
