import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tile, BarTileConfig, LegendPositions, DataLabelDisplayOptions } from '@models/dashboard';
​​
@Component({
  selector: 'app-tile-bar-editor',
  templateUrl: './bar.component.editor.html',
  styleUrls: ['./bar.component.editor.scss']
})
export class TileBarEditorComponent implements OnInit {

  @Input() tile: Tile;
  @Output() update = new EventEmitter();

  public config: BarTileConfig;
  public legendPositions;
  public dataLabelDisplayOptions;
​
  ngOnInit() {
    this.config = new BarTileConfig();
    this.legendPositions = Object.keys(LegendPositions).map(k => LegendPositions[k]);
    this.dataLabelDisplayOptions = Object.keys(DataLabelDisplayOptions).map(j => DataLabelDisplayOptions[j]);
    this.config = this.tile.config;
  }

  ngOnChange() {
    this.preview();
  }

  setDataLabelDisplayOption(option) {
    this.config.dataLabelDisplayOption = option;
    this.preview();
  }

  setLegendPosition(position) {
    this.config.legendPosition = position;
  }

  preview() {
    this.tile = Object.assign({}, this.tile);
    this.update.emit(this.tile);
  }

}
