import { Component, OnInit } from '@angular/core';
import { Dashboard, Question, DashboardSettings, Filter, Client, Survey } from '@models/dashboard';
import { SimpleModalComponent } from 'ngx-simple-modal';

import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { SurveyService } from '@app/services/survey/survey.service';

import { SortablejsOptions } from 'ngx-sortablejs';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';
import { BehaviorSubject, Observable } from 'rxjs';
import {DateDisplayHelper} from '@app/helpers/date.display.helper';

export interface DashboardModel {
  mode: string;
  title: string;
  client: Client;
  buttonText: string;
  dashboard: Dashboard;
}

@Component({
  selector: 'app-dashboard-admin-modal',
  templateUrl: './dashboard-admin-modal.component.html',
  styleUrls: ['./dashboard-admin-modal.component.scss']
})
export class DashboardAdminModalComponent extends SimpleModalComponent<DashboardModel, Dashboard> implements OnInit {
  mode: string;
  title = 'title';
  client: Client;
  buttonText: string;
  dashboard: Dashboard;

  fullRange;

  filterOptions: SortablejsOptions;

  questions;
  filters;

  accessKey;

  name;
  survey: Survey;
  surveys$: Observable<Survey[]>;

  public currentPalette = new BehaviorSubject<any>([]);

  loading = false;
  password = '';
  passwordLoading = false;
  passwordError = '';

  constructor(public dashboardService: DashboardService, private surveyService: SurveyService) {
    super();
  }

  ngOnInit() {
    this.accessKey = this.dashboard.accessKey;
    this.surveys$ = this.surveyService.getAll();

    if (this.mode === 'edit') {
      this.loading = true;
      this.dashboardService.getById(this.dashboard.id).subscribe((d) => {
        this.dashboard = d;

        if (!this.dashboard.meta.filters) {
          this.dashboard.meta.filters = [];
        }
        this.dashboard.meta.filters.forEach((filter) => {
          filter.rename = false;
        });

        if (!this.dashboard.meta.adminFilters) {
          this.dashboard.meta.adminFilters = [];
        }

        if (this.dashboard.isPasswordProtected) {
          // The field is disabled so it's just to show that a password exists
          this.password = '********************';
        }

        this.currentPalette.next(this.dashboard.meta.palette);
        this.loading = false;
      });
    }

    this.surveyService.getQuestions(this.dashboard.accessKey).subscribe((questions) => {
      this.questions = questions;
    });
  }

  setDateRange(range: any) {
    if (range === null) {
      this.dashboard.meta.fullRange = null;
    } else {
      this.dashboard.meta.fullRange = DateDisplayHelper.getFilterRange(range);
      this.fullRange = DateDisplayHelper.getFilterRange(range);
    }
  }

  addUserFilter(filter) {
    if (filter && this.dashboard.meta.filters) {
      filter.options.forEach((x) => {
        x.title = QuestionTitleHelper.getQuestionOptionTitle(x.title);
      });

      filter.options = filter.options.sort((a, b) => a.internalId - b.internalId);

      this.dashboard.meta.filters.push(filter);
    }
  }

  addAdminFilter(question) {
    if (question && this.dashboard.meta.adminFilters) {
      question.options.forEach((x) => {
        x.title = QuestionTitleHelper.getQuestionOptionTitle(x.title);
      });

      question.options = question.options.sort((a, b) => a.internalId - b.internalId);

      this.dashboard.meta.adminFilters.push({
        question: question,
        options: [null]
      });
    }
  }

  addAdminFilterOption(option, question) {
    let filters = this.dashboard.meta.adminFilters.filter((s) => s.question.id === question.id);

    if (filters.length > 0) {
      let filter = filters[0];
      filter.options.push(option);
      (filter as any).edit = false;
    }
  }

  removeUserFilter(filter) {
    this.dashboard.meta.filters = this.dashboard.meta.filters.filter((s) => s !== filter);
  }

  removeAdminFilter(question) {
    this.dashboard.meta.adminFilters = this.dashboard.meta.adminFilters.filter((s) => s.question.id !== question.id);
  }

  submit() {
    this.loading = true;

    switch (this.mode) {
      case 'edit':
        if (this.accessKey !== this.dashboard.accessKey) {
          this.dashboard.accessKey = this.accessKey;
        }

        this.dashboardService.save(this.dashboard).subscribe((dashboard) => {
          this.result = dashboard;
          this.close();
        });
        break;

      case 'add':
        this.dashboard.surveyId = this.survey.id.toString();
        this.dashboard.accessKey = this.dashboardService.getRandomToken();
        this.dashboard.client = this.client;

        if (!this.dashboard.name || this.dashboard.name.length === 0) {
          this.dashboard.surveyTitle = this.survey.title;
          this.dashboard.name = this.survey.title;
        }

        this.dashboardService.create(this.dashboard).subscribe((dashboard) => {
          this.result = dashboard;
          this.close();
        });
        break;
    }
  }

  setSerialNumber(question: Question) {
    if (!this.dashboard.meta) {
      this.dashboard.meta = new DashboardSettings();
    }
    this.dashboard.meta.link = question;
  }

  resetSerialNumber() {
    this.dashboard.meta.link = null;
  }

  setPalette(palette) {
    if (!this.dashboard.meta) {
      this.dashboard.meta = new DashboardSettings();
    }
    this.dashboard.meta.palette = palette;
  }

  setPassword() {
    this.passwordError = '';
    if (this.password.length > 5) {
      this.passwordLoading = true;
      this.dashboardService.setDashboardPassword(this.dashboard.id, this.password).subscribe(
        (response) => {
          this.dashboard.isPasswordProtected = true;
          this.passwordLoading = false;
        },
        (error) => {
          this.passwordLoading = false;
          this.passwordError = 'Error setting password';
        }
      );
    } else {
      this.passwordError = 'Password must be at least 5 character long';
    }
  }

  removePassword() {
    this.passwordLoading = true;
    this.passwordError = '';
    this.dashboardService.removeDashboardPassword(this.dashboard.accessKey).subscribe(
      (response) => {
        this.dashboard.isPasswordProtected = false;
        this.passwordLoading = false;
        this.password = '';
      },
      (error) => {
        this.passwordLoading = false;
        this.passwordError = 'Error removing password';
      }
    );
  }

  cancel() {
    this.close();
  }
}
