import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { Question, Filter } from '@app/models/dashboard';
import _ from 'lodash';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.scss']
})
export class DashboardFiltersComponent implements OnInit {
  @Input() data: Question[];
  @Input() published: any;

  jimmy = [];
  filters: any[];

  constructor(public dashboardService: DashboardService) {}

  ngOnInit() {
    this.filters = [{ type: 'range' }];
  }

  setOption(o: {}) {
    // question: Question, optionId: number) {

    // console.log(o);

    // let value = optionId;
    // this.dashboardService.selectedDashboard.filters.forEach(f => {
    //   if (f.questionId === question.id && f.valueId === optionId) {
    //     value = undefined;
    //   }
    // });
    // this.dashboardService.setFilter(question.id, value);
  }

  addFilter(filter) {
    // console.log(filter);
    this.jimmy.push(filter);
  }

  getSelected(question: Question) {
    const filter = _.find(this.dashboardService.selectedDashboard.filters, ['questionId', question.id]);
    if (filter) {
      return filter;
    }
  }

  isSelected(question: Question, optionId: number) {
    const filter = _.find(this.dashboardService.selectedDashboard.filters, ['questionId', question.id]);
    if (filter && filter.valueId === optionId) {
      return true;
    }
    return false;
  }

  updateFilter(o) {

    let filter = <Filter>{
      question: {
        id: o.question.id,
        internalId: o.question.internalId,
        alias: o.question.alias,
        title: o.question.title
      },
      options: [] 
    };

    filter.options.push({
      id: o.option.id,
      internalId: o.option.internalId,
      value: o.option.value,
      title: o.option.title
    });
    this.dashboardService.toggleFilter(filter);
  }

  clear(question) {
    this.dashboardService.toggleFilter(question.internalId);
  }
}
