import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@app/auth/auth.guard';
import { DevelopmentGuard } from './auth/development.guard';

import { CallbackComponent } from '@auth/callback/callback.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.page';
import { AdminComponent } from '@pages/admin/admin.page';
import { AdminClientComponent } from '@pages/admin-client/admin-client.component';
import { AdminSurveysComponent } from '@pages/admin-surveys/admin-surveys.component';
import { AdminSurveyComponent } from '@pages/admin-survey/admin-survey.component';

const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'dashboard/:identifier',
    component: DashboardComponent,
    canActivate: [DevelopmentGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/surveys',
    component: AdminSurveysComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/survey/:surveyId',
    component: AdminSurveyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/client/:clientId',
    component: AdminClientComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'admin' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
