import { Component, OnInit, Input, Output, SimpleChanges, OnChanges } from '@angular/core';

import { Tile, Filter, ChartConfig, Question, DateRange } from '@models/dashboard';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateDisplayHelper } from '@app/helpers/date.display.helper';
import { ChartService } from '@app/services/chart/chart.service';
import { ChartDisplayHelper } from '@app/helpers/chart.display.helper';

@Component({
  selector: 'app-tile-responses-display',
  templateUrl: './responses.component.display.html',
  styleUrls: ['./responses.component.display.scss']
})
export class TileResponsesDisplayComponent implements OnInit, OnChanges {
  @Input() tile: Tile;
  @Input() filters: Filter[];
  @Output() loading: boolean;

  public question: Question;
  public data: any;

  public config: ChartConfig;

  private ngUnsubscribe = new Subject();

  initializing: boolean;

  dateRange: DateRange;
  surveyResponsePeriod: string;

  constructor(public dashboardService: DashboardService, private chartService: ChartService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.tile && !changes.tile.isFirstChange()) || (changes.filters && !changes.filters.isFirstChange())) {
      this.render();
    }
  }

  ngOnInit() {
    this.initializing = true;

    this.config = this.tile.config as ChartConfig;

    this.dashboardService.dateRange.subscribe((range) => {
      this.dateRange = range;
      this.render();
    });

    this.dashboardService.responseRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((range) => {
      let displayrange = DateDisplayHelper.getPeriodName(range.startDate, range.endDate);
      this.surveyResponsePeriod = displayrange;
    });

    this.dashboardService.currentFilters.subscribe((filters) => {
      this.render();
    });

    this.initializing = false;
    this.render();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  render() {
    if (!this.initializing) {
      this.loading = true;

      if ((this.tile.config as ChartConfig).sources.length > 0) {
        const adminFilters = this.dashboardService.selectedDashboard.meta.adminFilters;

        let allfilters;
        if (adminFilters && this.filters) {
          allfilters = adminFilters.concat(this.filters);
        } else if (adminFilters) {
          allfilters = adminFilters;
        } else if (this.filters) {
          allfilters = this.filters;
        }
        const payload = ChartDisplayHelper.getPayload(this.tile.config, allfilters, this.tile.type, this.dateRange);
        payload.type = 'responses';

        this.chartService.getRawData(payload, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(
          (response) => {
            if (response) {
              this.data = response;
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  copyTable() {
    const range = document.createRange();
    let element = document.getElementById('responsesTable');
    element.classList.add('export');
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    element.classList.remove('export');
  }
}
