import { Component, Input, OnInit, OnChanges, ViewChild, Output, SimpleChanges } from '@angular/core';

import { Tile, Filter, ChartConfig, DateRange } from '@models/dashboard';
import { ChartOptions } from 'chart.js';
import { Label, BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SurveyService } from '@app/services/survey/survey.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ChartDisplayHelper } from '@app/helpers/chart.display.helper';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';

@Component({
  selector: 'app-tile-pie-display',
  templateUrl: './pie.component.display.html',
  styleUrls: ['./pie.component.display.scss']
})
export class TilePieDisplayComponent implements OnInit, OnChanges {
  @Input() tile: Tile;
  @Input() filters: Filter[];
  @Output() loading: boolean;
  public data: any;
  public labels: Label[];

  dateRange: DateRange;

  initializing: boolean;

  public chartOptions: ChartOptions = {
    cutoutPercentage: 50,
    legend: {
      labels: {
        usePointStyle: true,
        padding: 20
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {},
    scales: {},
    plugins: {}
  };

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(public dashboardService: DashboardService, private surveyService: SurveyService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.tile && !changes.tile.isFirstChange()) || (changes.filters && !changes.filters.isFirstChange())) {
      this.render();
    }
  }

  ngOnInit() {
    this.initializing;
    this.dashboardService.dateRange.subscribe(range => {
      this.dateRange = range;
      this.render();
    });

    this.dashboardService.currentFilters.subscribe(filters => {
      this.render();
    });

    this.initializing = false;
    this.render();
  }

  render() {
    if (!this.initializing) {
      this.loading = true;

      // this.colourPalette = this.tile.config["colourPalette"]
      //   ? this.tile.config["colourPalette"]
      //   : ["#47BCCC", "#BF6177", "#FFA359", "#A1A83A", "#563454"];
      this.chartOptions.legend.display = this.tile.config['showLegend'];

      // console.log(
      //   "this.tile.config datalabels",
      //   this.tile.config["showDataLabels"]
      // );

      this.chartOptions.plugins = {
        ChartDataLabels,
        datalabels: {
          display: this.tile.config['showDataLabels'],
          // display: this.tile.config['showDataLabels'],
          formatter(value, ctx) {
            let sum = 0;

            ctx.chart.data.datasets[0].data.forEach(element => {
              sum += element;
            });

            return ((value / sum) * 100).toFixed(1) + '%';
          }
        }
      };
    }

    const adminFilters = this.dashboardService.selectedDashboard.meta.adminFilters;
    const payload = ChartDisplayHelper.getPayload(this.tile.config as ChartConfig, this.filters.concat(adminFilters), this.tile.type, this.dateRange);

    this.surveyService.getLabelsAndDatasets(payload, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(
      response => {
        this.data = [
          {
            data: response.data,
            backgroundColor: this.dashboardService.getColourPalette().codes.slice(0, response.data.length)
          }
        ];
        this.labels = response.labels.map(x => QuestionTitleHelper.getQuestionOptionTitle(x));
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
}
