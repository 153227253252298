import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Question } from '@app/models/dashboard';
import _ from 'lodash';

import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';

@Component({
  selector: 'app-source-picker',
  templateUrl: './source-picker.component.html',
  styleUrls: ['./source-picker.component.scss']
})
export class SourcePickerComponent implements OnInit, OnChanges {
  @Input() sources: Question[];
  @Input() groupsOnly = false;
  @Input() selectedSources: Question[];
  @Input() value: Question;
  @Input() filter: string;
  @Input() placeholder: string;
  @Input() clear = true;
  @Input() addChildQuestions = true;
  @Input() showDetails = true;
  @Input() stripHtml = true;

  @Output() selected = new EventEmitter<Question>();
  @Output() clearCallback = new EventEmitter();

  constructor(private dashboardService: DashboardService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedSources) {
      if (this.filter) {
        this.filterSourcesBySelected(this.filter);
      }
    }
  }

  ngOnInit() {
    if (this.filter) {
      this.filterSourcesBySelected(this.filter);
    }

    if (this.stripHtml) {
      this.sources = QuestionTitleHelper.filterQuestionTitles(this.sources);
    }
  }

  filterSourcesBySelected(filter: string) {
    // filter by filter text
    if (this.sources && filter) {
      this.sources = _.filter(this.sources, question => {
        return _.includes(filter, question.questionType)
      });
    }

    // filter by already selected questions

    if (this.sources && this.selectedSources && this.selectedSources.length > 0) {
      this.sources = _.filter(this.sources, question => {
        // @JIMMY NEEDS TO CHECK IDS RATHER THAN OBJECT
        return !_.includes(this.selectedSources, question);
      });
    }
  }

  select(source: any) {
    if (this.groupsOnly) {
      this.selected.emit(source);
    } else {
      if (source && source.questionType && source.questionType === 'TABLE' && this.addChildQuestions) {
        source.subQuestions.forEach(subQuestion => {
          this.selected.emit(subQuestion);
        });
      } else {
        this.selected.emit(source);
      }
    }
  }

  search(term: string, item: any) {
    const t = term.toLocaleLowerCase();
    if (item.alias) {
      return item.title.toLocaleLowerCase().indexOf(t) > -1 || item.alias.toLocaleLowerCase().indexOf(t) > -1;
    } else {
      return item.title.toLocaleLowerCase().indexOf(t) > -1;
    }
  }

  compare(question1: Question, question2: Question): boolean {
    if (question2) {
      return question1.id === question2.id;
    }
  }
}
