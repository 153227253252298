import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tile,  LineTileConfig } from '@models/dashboard';
​
import * as _ from 'lodash';
​
@Component({
  selector: 'app-tile-line-editor',
  templateUrl: './line.component.editor.html',
  styleUrls: ['./line.component.editor.scss']
})
export class TileLineEditorComponent implements OnInit {


  @Input() tile: Tile;
  @Output() update = new EventEmitter();

  public config: LineTileConfig;
  public legendPositions;
​
  ngOnInit() {
    this.config = new LineTileConfig();
    this.config = this.tile.config;
  }

  ngOnChange() {
    this.preview();
  }

  preview() {
    this.tile = Object.assign({}, this.tile);

    this.update.emit(this.tile);
  }
}
