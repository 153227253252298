import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Tile } from '@models/dashboard';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-tile-text-editor',
  templateUrl: './text.component.editor.html',
  styleUrls: ['./text.component.editor.scss']
})
export class TileTextEditorComponent implements OnInit {

  @Input() tile: Tile;
  @Output() update = new EventEmitter<any>();

  public Editor = ClassicEditor;

  contentUpdate = new Subject<string>();

  constructor() {
    this.contentUpdate
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe(value => {
        this.update.emit(this.tile);
      });
  }

  ngOnInit() {

    console.log('VALUE', this.tile.config['text']);

    if (!this.tile.config['text']) {
      this.tile.config['text'] = '';
    }
  }

}
