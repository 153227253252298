import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.loggedIn) {
      return this.auth.getTokenSilently$().pipe(
        mergeMap(token => {
          const tokenReq = req.clone({
            setHeaders: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': environment.apiKey
            }
          });
          return next.handle(tokenReq);
        }),
        catchError(err => throwError(err))
      );
    } else {
      const tokenReq = req.clone({
        setHeaders: {
          'x-api-key': environment.apiKey
        }
      });
      return next.handle(tokenReq);
    }
  }
}
