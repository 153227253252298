import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from '@aspnet/signalr';
// import { SignalViewModel } from "../models/signal-view-model";

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  signalReceived = new EventEmitter<any>();

  public content: any;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiUrl + '/synchub') // use your api adress here and make sure you use right hub name.
      .build();
      this.connect();

      this.registerSignalEvents();
  }

  public connect() {
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      this.hubConnection.start().catch(err => console.log(err));
    }
  }

  public disconnect() {
    this.hubConnection.stop();
  }

  private registerSignalEvents() {
    this.hubConnection.on('SignalMessageReceived', (data: any) => {
      this.signalReceived.emit(data);
    });
  }

  public getMessage(next) {
    this.hubConnection.on('SendMessage', (message) => {
      next(message);
    });
  }
}
