import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewEncapsulation
} from "@angular/core";
import { GridsterConfig } from "angular-gridster2";
import * as _ from "lodash";

import { Tile } from "@models/dashboard";

import { DashboardService } from "@app/services/dashboard/dashboard.service";

@Component({
  selector: "app-grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class GridComponent implements OnInit {
  @Input() tiles: Tile[];
  @Input() editing: boolean;

  @Output() update = new EventEmitter();
  @Output() edit = new EventEmitter();

  options: GridsterConfig;

  constructor(public dashboardService: DashboardService) {}

  ngOnInit() {
    this.options = {
      // Have commented these out to stop updating ALL tiles, when only the self-tile needs updates.
      itemChangeCallback: _.debounce(() => {
        // console.log("ITEM CHANGE CALLBACK");
        this.save();
      }, 100),
      itemRemovedCallback: _.debounce(() => {
        // console.log("ITEM REMOVE CALLBACK");
        this.save();
      }, 100),
      gridType: "verticalFixed",
      fixedRowHeight: 80,
      minCols: 12,
      maxCols: 12,
      minItemCols: 4,
      minItemRows: 2,
      margin: 40,
      displayGrid: "none",
      pushItems: true,
      swap: true,
      compactType: "compactUp",
      disableScrollHorizontal: true,
      useTransformPositioning: false,
      isMobile: false,
      mobileBreakPoint: 600,
      mobileModeEnabled: true,
      draggable: {
        delayStart: 0,
        enabled: true,
        ignoreContentClass: "gridster-item-content",
        ignoreContent: true,
        dragHandleClass: "drag-handler",
        dropOverItems: false
      },
      resizable: {
        enabled: true
      }
    };
  }

  toggle(tile, setting, e) {
    e.stopPropagation();
    tile.settings[setting] = !tile.settings[setting];
    this.save(tile);
  }

  editTitle(tile) {
    if (this.editing) {
      tile.editing.title = true;
    }
  }

  editTile(tile) {
    this.edit.emit(tile);
  }

  save(tile?) {
    if (tile) {
      this.update.emit({ tile, loader: "title" });
    } else {
      this.update.emit();
    }
  }

  remove(tile) {
    this.tiles.splice(this.tiles.indexOf(tile), 1);
  }
}
