import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import { ClipboardService } from 'ngx-clipboard';

export interface ShareModel {
  title: string;
  buttonText: string;
}

@Component({
  selector: 'app-dashboard-share',
  templateUrl: './dashboard-share.component.html',
  styleUrls: ['./dashboard-share.component.scss']
})
export class DashboardShareComponent extends SimpleModalComponent<ShareModel, boolean> implements OnInit  {

  mode: string;
  title: string = 'title';
  buttonText: string;

  code: string;

  confirmCopy: boolean = false;

  constructor(
    private _clipboardService: ClipboardService
  ) { 
    super();
  }

  ngOnInit() {
    // if (this.tag.id) {
    //   this.mode = 'edit';
    // } else {
    //   this.mode = 'create';
    // }
    this.generate(64);
  }

  generate(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.code = result;
  }

  copy(code) {
    this._clipboardService.copyFromContent(code);
    this.confirmCopy = true;
    setTimeout( () => {
      this.confirmCopy = false;
    }, 1000);
  }


  confirm() {
    // we set modal result as true on click on confirm button,
    // then we can get modal result from caller code
    // this.result = true;
    // if (this.mode === 'edit') {
    //   this.userTagService.update(this.tag).subscribe(
    //     tag => {
    //       this.close();
    //     }
    //   );
    // } else {
    //   this.userTagService.add(this.tag).subscribe(
    //     tag => {
    //       this.close();
    //     }
    //   );
    // }

    this.close();

  }

  delete() {
    // this.userTagService.delete(this.tag.id).subscribe(
    //   tag => {
    //     this.close();
    //   }
    // );
  }

  cancel() {
    this.close();
  }


}
