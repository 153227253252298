import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { ChartService } from '@services/chart/chart.service';

import * as moment from 'moment';
import _ from 'lodash';

import { Question, SummaryPayload, DateRange, Filter, Summary, MyCaW } from '@models/dashboard';
import { SurveyService } from '@app/services/survey/survey.service';
import { DateDisplayHelper } from '@app/helpers/date.display.helper';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';
import { AuthService } from '@app/auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mycaw',
  templateUrl: './mycaw.component.html',
  styleUrls: ['./mycaw.component.scss']
})
export class MycawComponent implements OnInit {
  @Input() mycaw: MyCaW;
  @Output() mycawChange: EventEmitter<MyCaW> = new EventEmitter<MyCaW>();

  data;
  payload: SummaryPayload;
  questions: Question[];

  dateRange: DateRange;
  surveyResponseRange: DateRange;

  notes: [
    {
      sourceId: number;
      text: string;
    }
  ];

  loading = {
    init: false,
    action: false
  };
  error: string;

  period: string;
  surveyResponsePeriod: string;
  surveyName: string;
  adminFilters: Filter[];

  initializing: boolean;

  private ngUnsubscribe = new Subject();

  constructor(
    public dashboardService: DashboardService,
    private chartService: ChartService,
    private surveyService: SurveyService,
    private authService: AuthService
  ) {
    this.loading.init = true;
    this.error = '';
    this.dateRange = { startDate: '', endDate: '' };
    this.period = DateDisplayHelper.getPeriodName(this.dateRange.startDate, this.dateRange.endDate);
    this.surveyName = dashboardService.surveyTitle;
    this.adminFilters = dashboardService.selectedDashboard.meta.adminFilters;
  }

  ngOnInit() {
    this.initializing = true;

    if (!this.mycaw) {
      this.mycaw = new MyCaW();
    }
    this.dateRange = { startDate: '', endDate: '' };
    this.surveyResponsePeriod = null;

    this.surveyService.questions.pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.questions = x;
      this.updateChart();
    });

    this.dashboardService.dateRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(range => {
      this.dateRange = range;
      this.period = DateDisplayHelper.getPeriodName(this.dateRange.startDate, this.dateRange.endDate);

      this.dashboardService.responseRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(range => {
        this.surveyResponseRange = range;
        let displayrange = DateDisplayHelper.getPeriodName(this.surveyResponseRange.startDate, '');
        this.surveyResponsePeriod = displayrange;
      });

      this.updateChart();
    });

    this.dashboardService.currentFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe(filters => {
      this.updateChart();
    });

    this.initializing = false;
    this.updateChart();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateChart = () => {
    if (!this.initializing) {
      this.loading.init = true;
      if (this.mycaw.seriesQuestion) {
        if (this.mycaw.measurementSources && this.mycaw.measurementSources.length > 0) {
          let dateRange = undefined;
          if (this.dateRange.startDate && this.dateRange.endDate) {
            dateRange = {
              startDate: moment(this.dateRange.startDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay,
              endDate: moment(this.dateRange.endDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay
            };
          } else {
            dateRange = {
              startDate: DateDisplayHelper.getBeginingOfPreviousMonth(),
              endDate: DateDisplayHelper.getEndOfPreviousMonth()
            };
          }

          let link = null;

          if (this.dashboardService.selectedDashboard.meta && this.dashboardService.selectedDashboard.meta.link) {
            link = this.dashboardService.selectedDashboard.meta.link;
          }

          const fullRange = {
            startDate: moment(this.surveyResponseRange.startDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay,
            endDate: DateDisplayHelper.getEndOfPreviousMonth()
          };

          const payload = {
            periodRange: dateRange,
            fullRange,
            groupQuestion: this.mycaw.seriesQuestion,
            groupQuestionOptions: this.mycaw.seriesQuestionOptions,
            sources: this.mycaw.measurementSources,
            filters: this.dashboardService.getFilters(),
            link: link,
            linkOptions: this.mycaw.linkOptions,
            requireCompleteResponses: this.mycaw.requireFullMeasureResponse
          };

          this.chartService.getMycawTableData(payload, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(
            response => {
              this.data = response;

              if (response) {
              }

              this.loading.init = false;
            },
            error => {
              this.loading.init = false;
              this.error = 'There was are error collecting summary data, please try again shortly. If the problem persists, please get in touch. ';
            }
          );
        } else {
          this.loading.init = false;
          this.error = 'Select a source question to create summary';
        }
      } else {
        this.loading.init = false;
        this.error =
          "This survey does not have a question with the alias 'Session'. As a result, summary data cannot be presented. To resolve the issue, add the alias to the appropriate question in your survey.";
        this.data = null;
      }
    }
  };

  questionAdd = (question: Question) => {
    if (this.mycaw.measurementSources.length == 0) {
      const mycawQuestion = new Question();
      mycawQuestion.subQuestions = [];
      mycawQuestion.title = 'MYCaw overall';
      mycawQuestion.id = 1;
      mycawQuestion.alias = 'MYCaw overall';
      mycawQuestion.internalId = 1;

      this.mycaw.measurementSources.push(mycawQuestion);
    }

    const mycawQuestion = this.mycaw.measurementSources[0];

    mycawQuestion.subQuestions.push(question);
    question.summaryDisplay = true;
    mycawQuestion.subQuestions.sort((a, b) => a.internalId - b.internalId);

    this.mycawChange.emit(this.mycaw);
    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  }

  mycawTitleEdit(mycaw) {
    mycaw.rename = !mycaw.rename;
  }

  questionEdit(question: Question) {
    question.rename = !question.rename;
  }

  questionRemove = (question: Question) => {
    this.mycaw.measurementSources = [];

    this.mycawChange.emit(this.mycaw);
    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  };

  groupQuestionAdd = (question: Question) => {
    this.mycaw.seriesQuestion = question;
    this.mycawChange.emit(this.mycaw);
    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  };

  getSubQuestionResult(data, subQuestion) {
    return data.subResults.find(x => x.question.internalId === subQuestion.internalId);
  }

  reset() {
    this.mycaw.seriesQuestion = null;
    this.mycawChange.emit(this.mycaw);
    this.dashboardService.saveSelectedDashboard();
    this.updateChart();
  }

  save() {
    this.dashboardService.saveSelectedDashboard();
  }

  delete() {
    if (confirm('Are you sure you want to delete this table?')) {
      this.dashboardService.removeMycaw(this.mycaw);
    }
  }

  update() {
    this.mycawChange.emit(this.mycaw);
    this.dashboardService.saveSelectedDashboard();
    this.updateChart();
  }

  optionsChange = () => {
    this.update();
  };

  copySummary() {
    const range = document.createRange();
    let element = document.getElementById('summaryTables');
    element.classList.add('export');
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    element.classList.remove('export');
  }

  loggedIn() {
    return this.authService.loggedIn;
  }
}
