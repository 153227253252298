import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { BehaviorSubject } from 'rxjs';

import {
  Dashboard,
  Tile,
  CountTileConfig,
  TextTileConfig,
  TimelineTileConfig,
  TilesTypes,
  BarTileConfig,
  Demographic,
  DateRange,
  Summary,
  Client,
  MyCaW,
  ChartConfig,
  DashboardPassword
} from '@models/dashboard';

import { environment } from 'environments/environment';

import { Filter } from '@app/models/dashboard';

import * as _ from 'lodash';
import { DateDisplayHelper } from '@app/helpers/date.display.helper';
import { Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private currentDashboard = new BehaviorSubject<Dashboard>(new Dashboard());

  public observableDashboard = this.currentDashboard.asObservable();

  public selectedDashboard: Dashboard;
  public surveyId;
  public surveyTitle;
  public dateRange = new BehaviorSubject<DateRange>({
    startDate: '',
    endDate: ''
  });
  public currentFilters = new BehaviorSubject<Filter[]>([]);
  public responseRange = new ReplaySubject<DateRange>(1);
  public allDashboards = new ReplaySubject<Dashboard[]>(1);

  loading;
  editing;
  view = 'charts';
  offset;

  constructor(private http: HttpClient) {}

  getCurrentSurveyAccessCode() {
    return this.selectedDashboard.accessKey;
  }

  setDateRange(range: any) {
    let formattedRange = DateDisplayHelper.getFilterRange(range);
    this.dateRange.next(formattedRange);
  }

  setDashboard(dashboard: Dashboard) {
    if (!dashboard.filters) {
      dashboard.filters = [];
    }

    if (!this.selectedDashboard) {
      this.selectedDashboard = dashboard;
    }

    this.fetchResponseRange(dashboard.accessKey).subscribe((x) => {
      this.responseRange.next(x);
    });

    if (dashboard.accessKey != this.selectedDashboard.accessKey) {
      this.selectedDashboard = dashboard;
      this.currentDashboard.next(dashboard);
    } else {
      this.currentDashboard.next(dashboard);

      this.selectedDashboard.lastUpdatedBy = dashboard.lastUpdatedBy;
      this.selectedDashboard.updatedOn = dashboard.updatedOn;
      this.selectedDashboard.publishedOn = dashboard.publishedOn;
      if (this.selectedDashboard.layouts) {
        this.selectedDashboard.layouts.published = dashboard.layouts.published;
      }

      dashboard.layouts.draft.tiles.forEach((tile, index) => {
        tile.loading.footer = false;
        tile.loading.title = false;
        if (this.selectedDashboard.layouts) {
          if (this.tileLayoutDifferent(tile, this.selectedDashboard.layouts.draft.tiles[index])) {
            this.selectedDashboard.layouts.draft.tiles[index] = tile;
          }

          this.selectedDashboard.layouts.draft.tiles[index].description = tile.description;
          this.selectedDashboard.layouts.draft.tiles[index].footnote = tile.footnote;
          this.selectedDashboard.layouts.draft.tiles[index].settings = tile.settings;
          this.selectedDashboard.layouts.draft.tiles[index].title = tile.title;

          if (!_.isEqual(tile.config, this.selectedDashboard.layouts.draft.tiles[index].config)) {
            this.selectedDashboard.layouts.draft.tiles[index].config = tile.config;
          }
        } else {
          this.selectedDashboard = dashboard;
        }
      });
    }

    this.surveyId = dashboard.surveyId;
    this.surveyTitle = dashboard.surveyTitle;
  }

  tileLayoutDifferent(tileA: Tile, tileB: Tile) {
    return tileA.x !== tileB.x || tileA.y !== tileB.y || tileA.rows !== tileB.rows || tileA.cols !== tileB.cols;
  }

  public toggleFilter(filter: Filter) {
    this.selectedDashboard.filters = _.filter(this.selectedDashboard.filters, (existingFilter: Filter) => {
      return existingFilter.question.internalId !== filter.question.internalId;
    });

    if (filter.options.length > 0) {
      this.selectedDashboard.filters.push(filter);
    }

    this.currentFilters.next(this.selectedDashboard.filters);
  }

  getFilters() {
    const adminFilters = this.selectedDashboard.meta.adminFilters;

    if (adminFilters) {
      adminFilters.forEach(f => {
        f.options = _.compact(f.options);
      });
    }
    return this.selectedDashboard.filters.concat(adminFilters);
  }

  addTile(type: TilesTypes, e, groupsOnly = false) {
    e.preventDefault();

    this.view = 'charts';

    let config;
    switch (type) {
      case TilesTypes.count:
        config = new CountTileConfig();
        break;
      case TilesTypes.text:
        config = new TextTileConfig();
        break;
      case TilesTypes.bar:
        config = new BarTileConfig();
        config.sourcesGroupsOnly = groupsOnly;
        break;
      case TilesTypes.timeline:
        config = new TimelineTileConfig();
        break;
      case TilesTypes.responses:
        config = new ChartConfig();
        Breakpoints;
    }

    let tile = new Tile({
      id: uuid(),
      type: type,
      cols: 4,
      rows: 4,
      y: 0,
      x: 0,
      config: config,
      title: '',
      lastRender: 0
    });

    this.selectedDashboard.layouts.draft.tiles.push(tile);

    this.update().subscribe((response) => {
      tile.lastRender = new Date().getTime();
    });
  }

  saveTile(tile: Tile) {
    this.selectedDashboard.layouts.draft.tiles.forEach((element, index) => {
      if (element.id === tile.id) {
        this.selectedDashboard.layouts.draft.tiles[index] = tile;
      }
    });
  }

  addDemographic(e) {
    e.preventDefault();

    this.view = 'other-data';

    if (!this.selectedDashboard.layouts.draft.demographics) {
      this.selectedDashboard.layouts.draft.demographics = [];
    }
    this.selectedDashboard.layouts.draft.demographics.push(new Demographic());
  }

  removeDemographic(demographic: Demographic) {
    this.selectedDashboard.layouts.draft.demographics = this.selectedDashboard.layouts.draft.demographics.filter(
      (item) => {
        return item !== demographic;
      }
    );
    this.saveSelectedDashboard();
  }

  addMyCaW(e) {
    e.preventDefault();

    this.view = 'other-data';

    if (!this.selectedDashboard.layouts.draft.mycaw) {
      this.selectedDashboard.layouts.draft.mycaw = [];
    }
    this.selectedDashboard.layouts.draft.mycaw.push(new MyCaW());
  }

  removeMycaw(mycaw: MyCaW) {
    this.selectedDashboard.layouts.draft.mycaw = this.selectedDashboard.layouts.draft.mycaw.filter((item) => {
      return item !== mycaw;
    });
    this.saveSelectedDashboard();
  }

  addMeasurements() {
    // this.selectedDashboard.layouts.draft.demographics.push(new Demographic());
  }

  addComments() {}

  fetchAllDashboards() {
    this.http.get<Dashboard[]>(environment.apiUrl + '/admin/dashboards').subscribe((dashboards) => {
      this.allDashboards.next(dashboards);
    });
  }

  getById(identifier): Observable<Dashboard> {
    return this.http.get<Dashboard>(environment.apiUrl + '/admin/dashboards/' + identifier).pipe(
      map((dashboard) => {
        dashboard.layouts.draft.tiles = this.processTiles(dashboard.layouts.draft.tiles);
        dashboard.layouts.published.tiles = this.processTiles(dashboard.layouts.published.tiles);
        if (!dashboard.layouts.draft.summary) {
          dashboard.layouts.draft.summary = new Summary();
        }
        if (!dashboard.layouts.published.summary.measurementSources) {
          dashboard.layouts.published.summary = new Summary();
        }

        return dashboard;
      })
    );
  }

  fetchResponseRange(accessCode: string): Observable<DateRange> {
    return this.http.get<DateRange>(environment.apiUrl + '/demographics/' + accessCode + '/range');
  }

  getByAccessCode(identifier, password): Observable<Dashboard> {
    return this.http.post<Dashboard>(environment.apiUrl + '/dashboards/' + identifier, { password }).pipe(
      map((dashboard) => {
        dashboard.layouts.draft.tiles = this.processTiles(dashboard.layouts.draft.tiles);
        dashboard.layouts.published.tiles = this.processTiles(dashboard.layouts.published.tiles);
        if (!dashboard.layouts.draft.summary) {
          dashboard.layouts.draft.summary = new Summary();
        }
        if (!dashboard.layouts.published.summary) {
          dashboard.layouts.published.summary = new Summary();
        }

        return dashboard;
      })
    );
  }

  processTiles(tiles: Tile[]): Tile[] {
    return tiles.map((tile) => {
      return new Tile(tile);
    });
  }

  getDashboardToSave(dashboard: Dashboard) {
    const dashboardToSave = _.omit(dashboard, ['client']);
    if (dashboard.client) {
      dashboardToSave.client = new Client();
      dashboardToSave.client.id = dashboard.client.id;
    }
    return dashboardToSave;
  }

  create(dashboard): Observable<Dashboard> {
    const payload = this.getDashboardToSave(dashboard);
    return this.http.post<Dashboard>(environment.apiUrl + '/admin/dashboards', payload);
  }

  save(dashboard): Observable<Dashboard> {
    const payload = this.getDashboardToSave(dashboard);
    return this.http.put<Dashboard>(environment.apiUrl + '/admin/dashboards', payload);
  }

  saveSelectedDashboard() {
    const payload = this.getDashboardToSave(this.selectedDashboard);
    this.http.put<Dashboard>(environment.apiUrl + '/admin/dashboards', payload).subscribe((result) => {});
  }

  update(): Observable<Dashboard> {
    const payload = this.getDashboardToSave(this.selectedDashboard);
    return this.http.put<Dashboard>(environment.apiUrl + '/admin/dashboards', payload);
  }

  publish(): Observable<Dashboard> {
    return this.http.put<Dashboard>(
      environment.apiUrl + '/admin/dashboards/' + this.selectedDashboard.id + '/publish/',
      {}
    );
  }

  delete(dashboard) {
    return this.http.delete(environment.apiUrl + '/admin/dashboards/' + dashboard.id);
  }

  getColourPalette() {
    if (!this.selectedDashboard.meta.palette) {
      this.selectedDashboard.meta.palette = {
        name: '1',
        codes: ['#4aaaaa', '#bcbcbc', '#bababa', '#878787', '#4d4d4d']
      };
    }
    return this.selectedDashboard.meta.palette;
  }

  regenerateAccessKey() {
    return this.getRandomToken();
  }

  getRandomToken() {
    const tokenArrray = crypto.getRandomValues(new Uint32Array(32));
    const token = this.toHexString(tokenArrray);
    return token;
  }

  toHexString(tokenArrray: Uint32Array) {
    return Array.from(tokenArrray, function (byte) {
      // tslint:disable-next-line: no-bitwise
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    }).join('');
  }

  setDashboardPassword(dashboardId: number, password: string): Observable<Dashboard> {
    const payload = {
      dashboardId,
      password
    } as DashboardPassword;
    return this.http.post<Dashboard>(environment.apiUrl + '/admin/secure', payload);
  }

  removeDashboardPassword(dashboardAccessKey: string): Observable<Dashboard> {
    return this.http.delete<Dashboard>(environment.apiUrl + '/admin/secure/' + dashboardAccessKey);
  }
}
