import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as _ from 'lodash';
import { Tile, TimelineTileConfig } from '@app/models/dashboard';
// import { SurveyService } from '@app/services/survey/survey.service';
// import { DashboardService } from '@app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-tile-timeline-editor',
  templateUrl: './timeline.component.editor.html',
  styleUrls: ['./timeline.component.editor.scss']
})
export class TileTimelineEditorComponent implements OnInit {
  @Input() tile: Tile;
  @Output() update = new EventEmitter();

  public config: TimelineTileConfig;
  // public legendPositions;
  // public dataLabelDisplayOptions;
  ngOnInit() {
    this.config = new TimelineTileConfig();
    this.config = this.tile.config;
  }

  ngOnChange() {
    this.preview();
  }

  // setDataLabelDisplayOption(option) {
  //   this.config.dataLabelDisplayOption = option;
  //   // console.log(this.config.dataLabelDisplayOption);
  //   this.preview();
  // }

  // setLegendPosition(position) {
  //   this.config.legendPosition = position;
  // }

  preview() {
    this.tile = Object.assign({}, this.tile);
    this.update.emit(this.tile);
  }
}
