import { Component, OnInit, AfterViewChecked, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { TilesTypes, Question, Filter, Dashboard } from '@models/dashboard';

import { SimpleModalService } from 'ngx-simple-modal';
import { DashboardAdminModalComponent } from '@components/dashboard-admin-modal/dashboard-admin-modal.component';

import { environment } from '@env/environment';

import _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked {

  @ViewChild('height', {static: false} ) height: ElementRef;

  @Input() status;
  @Input() loading;

  @Output() offset = new EventEmitter();

  range;

  editing = false;

  dashboard: Dashboard;

  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
      - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
      ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  public lastEnd: Date = this.today;
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  public yearEnd: Date = this.today;

  presets = [];

  constructor(
    private router: Router,
    public auth: AuthService,
    public dashboardService: DashboardService,
    private simpleModalService: SimpleModalService
    ) { }

  ngOnInit() {
    this.auth.localAuthSetup();
    this.dashboardService.observableDashboard.subscribe(db => {
      this.dashboard = db;
    });

    for (let i = 0; i < 12; i++) {
      const m = moment().subtract(i, 'month');
      this.presets.push({
        label: m.format('MMMM YYYY'),
        start: m.clone().startOf('month').format(),
        end: m.clone().endOf('month').format()
      });
    }

    const quarters = [ 0, 1, 2, 3 ].map(i => {
        const n = moment().subtract(i, 'Q');
        const label = 4 - i;
        this.presets.push({
          label: 'NHS Q' + label,
          start: n.clone().startOf('Q').format(),
          end: n.format()
        });
      }
    );

  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.offset.emit(this.height.nativeElement.offsetHeight + 30);
    });
  }

  isDashboard() {
    return this.router.url.includes('dashboard');
  }

  getEnvironment() {
    return environment.assetUrl;
  }

  editDashboard(dashboard) {
    console.log('dashboard', dashboard);
    const modal = this.simpleModalService
      .addModal(DashboardAdminModalComponent, {
        mode: 'edit',
        title: 'Edit Dashboard',
        client: dashboard.client,
        buttonText: 'Save',
        dashboard: _.cloneDeep(dashboard)
      })

      .subscribe(result => {
        if (result) {

          // THIS SHOULD UPDATE THE DASHBOARD OBJECT IN THE SERVICE SO THAT IT IN EFFECT FORCES A REFRESH
          // this.dashboardService.setDashboard(dashboard); - doesn't work

          window.location.reload();

          console.log(result);
        }
      });
  }

  updateFilter(o) {

    console.log('Header component. UpdateFilter');

    let options = [];

    if (o.options) {
      o.options.forEach(option => {
        options.push(
          {
            id: option.id,
            internalId: option.internalId,
            value: option.value,
            title: option.title
          }
        );
      });
    }

    const filter = {
      question: {
        id: o.question.id,
        internalId: o.question.internalId,
        alias: o.question.alias,
        title: o.question.title
      },
      options
    } as Filter;
    // console.log(filter);

    this.dashboardService.toggleFilter(filter);
  }

  getSelected(question: Question) {
    // const filter = this.dashboardService.selectedDashboard.filters.find(x => {
    // const filter = this.dashboard.filters.find(x => {
    //   if (x.question.id === question.id) {
    //     return x;
    //   }
    //   return undefined;
    // });
    // if (filter) {
    //   const option = question.options.find(x => {
    //     if (filter.option.id === x.id) {
    //       return x;
    //     }
    //     return undefined;
    //   });
    //   if (option) {
    //     console.log('get selected filter');
    //     return option;
    //   }
    // }
  }

  get tilesTypes() {
    return TilesTypes;
  }
}
