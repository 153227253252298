import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Tile, TilesTypes } from '@app/models/dashboard';
import { DashboardService } from '@services/dashboard/dashboard.service';

import _ from 'lodash';

export interface EditorModel {
  title: string;
  tile: Tile;
  buttonText: string;
}

@Component({
  selector: 'app-editor-modal',
  templateUrl: './editor-modal.component.html',
  styleUrls: ['./editor-modal.component.scss']
})
export class EditorModalComponent extends SimpleModalComponent<EditorModel, boolean> implements OnInit {
  loading;

  tile: Tile;
  previewTile: Tile;

  constructor(private dashboardService: DashboardService) {
    super();
  }

  ngOnInit() {
    this.previewTile = _.cloneDeep(this.tile);
  }

  update(tile) {
    console.log('updated tile ', tile);
    // this.previewTile = tile;
  }

  save() {
    // console.log('saved tile ', this.previewTile);
    this.dashboardService.saveTile(this.previewTile);

    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }

  get tilesTypes() {
    return TilesTypes;
  }
}
