import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Tile, ChartConfig } from '@models/dashboard';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import * as _ from 'lodash';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { SurveyService } from '@app/services/survey/survey.service';

@Component({
  selector: 'app-tile-responses-editor',
  templateUrl: './responses.component.editor.html',
  styleUrls: ['./responses.component.editor.scss']
})
export class TileResponsesEditorComponent implements OnInit {

  @Input() tile: Tile;
  @Output() update = new EventEmitter();

  public config: ChartConfig;
  public title: string;
  public Editor = ClassicEditor;

  constructor(private surveyService: SurveyService, public dashboardService: DashboardService) { }

  ngOnInit() {
    // console.log(this.tile.config);
    this.config = new ChartConfig();
  }

  ngOnChange() {
    this.preview();
  }

  preview() {
    this.tile.config = this.config;
    this.tile = Object.assign({}, this.tile);
    this.update.emit(this.tile);
  }
}
