import * as moment from 'moment';
import { DateRange } from '@app/models/dashboard';

export module DateDisplayHelper {
  export function getPeriodName(dateRangeStart: string, dateRangeEnd: string) {

    let startPeriod;
    let endPeriod 

    if (dateRangeStart) {
      startPeriod = moment(dateRangeStart).format('D MMM YY');
    } else {
      startPeriod = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('D MMM YY');
    }

    if (dateRangeEnd) {
      endPeriod = moment(dateRangeEnd).format('D MMM YY');
    } else {
      endPeriod = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('D MMM YY');
    }
    
    return `${startPeriod} - ${endPeriod} `;
  }

  export const dateFormat = 'YYYY/MM/DD';
  export const beginingOfDay = ' 00:00:00';
  const endOfDay = ' 23:59:59';

  export function getBeginingOfPreviousMonth() {
    return (
      moment()
        .date(1)
        .subtract(1, 'months')
        .format(dateFormat) + beginingOfDay
    );
  }

  export function getEndOfPreviousMonth() {
    return (
      moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(dateFormat) + endOfDay
    );
  }

  export function getFilterRange(range: any) {
    if (!range || range.length !== 2) {
      return { startDate: '', endDate: '' } as DateRange;
    }

    let startDate = moment(range[0]).toISOString();
    let endDate = moment(range[1])
      .endOf('day')
      .toISOString();

    return {
      startDate: `${startDate}`,
      endDate: `${endDate}`
    } as DateRange;
  }
}
