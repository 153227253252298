import { ChartConfig, TilesTypes, DateRange, Question } from '@app/models/dashboard';

export module ChartDisplayHelper {
  export function getPayload(config: ChartConfig, filters: any, type: TilesTypes, dateRange: DateRange) {
    let groupBy = null;
    let isGroupedByQuestion = false;

    if (type !== TilesTypes.pie) {
      groupBy = config.groupBy
        ? {
            id: config.groupBy.id,
            internalId: config.groupBy.internalId,
            alias: config.groupBy.alias,
            labelType: config.titleType,
            customTitle: config.groupBy.displayTitle,
            subQuestions: config.groupBy.subQuestions
          }
        : null;
      isGroupedByQuestion = config.isGroupedByQuestion ? config.isGroupedByQuestion : false;
    }

    let selectedDateRange = null;

    if (dateRange && dateRange.startDate !== '' && dateRange.endDate !== '') {
      selectedDateRange = dateRange;
    }

    let chartType = 'chart';

    if (this.isMycawScoresChart(config.sources)) {
      chartType = 'mycaw';
    }

    return {
      type: chartType,
      config: {
        sources: config['sources'].map(x => ({
          id: x.id as number,
          internalId: x.internalId,
          alias: x.alias,
          labelType: config.titleType,
          displayTitle: x.displayTitle,
          SubQuestions: x.subQuestions
        })),
        aggregateTitle: config['aggregateTitle'],
        groupBy: groupBy,
        labelType: config.titleType,
        isGroupedByQuestion: isGroupedByQuestion,
        sourcesGroupsOnly: config.sourcesGroupsOnly,
        filters: filters,
        dateRange: selectedDateRange
      }
    };
  }

  export function isMycawScoresChart(questions: Question[]) {
    return questions.some(x => {
      if (x.alias) {
        return x.alias.toLowerCase().indexOf('conscore') > -1;
      } else {
        return false;
      }
    });
  }

  export function getConfidenceLines(config: any) {
    return {
      drawTime: 'afterDatasetsDraw',
      events: ['click'],
      dblClickSpeed: 350,
      annotations: [
        {
          drawTime: 'afterDraw',
          id: 'a-line-1',
          type: 'line',
          borderDash: [3, 3],
          mode: config['isVertical'] ? 'horizontal' : 'vertical',
          scaleID: config['isVertical'] ? 'y-axis-0' : 'x-axis-0',
          value: '40',
          borderColor: 'red',
          borderWidth: 2,
          label: {
            backgroundColor: '#ffffff00',
            fontFamily: 'sans-serif',
            fontSize: 10,
            fontStyle: 'normal',
            fontColor: 'red',
            xPadding: 6,
            yPadding: 6,
            cornerRadius: 6,
            position: config['isVertical'] ? 'right' : 'top',
            xAdjust: config['isVertical'] ? -6 : 10,
            yAdjust: config['isVertical'] ? 8 : -6,
            enabled: true
            // content: '40'
          }
        },
        {
          drawTime: 'afterDraw',
          id: 'a-line-2',
          type: 'line',
          borderDash: [3, 3],
          mode: config['isVertical'] ? 'horizontal' : 'vertical',
          scaleID: config['isVertical'] ? 'y-axis-0' : 'x-axis-0',
          value: '60',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            backgroundColor: '#ffffff00',
            fontFamily: 'sans-serif',
            fontSize: 10,
            fontStyle: 'normal',
            fontColor: 'orange',
            xPadding: 6,
            yPadding: 6,
            cornerRadius: 6,
            position: config['isVertical'] ? 'right' : 'top',
            xAdjust: config['isVertical'] ? -6 : 10,
            yAdjust: config['isVertical'] ? 8 : -6,
            enabled: true
            // content: '60'
          }
        },
        {
          drawTime: 'afterDraw',
          id: 'a-line-3',
          type: 'line',
          borderDash: [3, 3],
          mode: config['isVertical'] ? 'horizontal' : 'vertical',
          scaleID: config['isVertical'] ? 'y-axis-0' : 'x-axis-0',
          value: '80',
          borderColor: 'green',
          borderWidth: 2,
          label: {
            backgroundColor: '#ffffff00',
            fontFamily: 'sans-serif',
            fontSize: 10,
            fontStyle: 'normal',
            fontColor: 'green',
            xPadding: 6,
            yPadding: 6,
            cornerRadius: 6,
            position: config['isVertical'] ? 'right' : 'top',
            xAdjust: config['isVertical'] ? -6 : 10,
            yAdjust: config['isVertical'] ? 8 : -6,
            enabled: true
            // content: '80'
          }
        }
      ]
    };
  }
}
