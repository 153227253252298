import { Component, OnInit, Input } from '@angular/core';
import { Question, Demographic, DateRange, Filter } from '@app/models/dashboard';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ChartService } from '@app/services/chart/chart.service';
import { SurveyService } from '@app/services/survey/survey.service';
import { DateDisplayHelper } from '@app/helpers/date.display.helper';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';
import * as moment from 'moment';
import { AuthService } from '@app/auth/auth.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss']
})
export class DemographicsComponent implements OnInit {
  @Input() demographic: Demographic;
  @Input() index: number;

  questions: Question[];
  dateRange: DateRange;
  surveyResponseRange: DateRange;

  data;
  titleDisplayLookup = {};

  loading = {
    init: false,
    action: false
  };

  rename = false;
  error: string;
  period: string;
  surveyResponsePeriod: string;
  surveyName: string;
  adminFilters: Filter[];
  allCount: number;
  rangeCount: number;

  private ngUnsubscribe = new Subject();

  constructor(
    public dashboardService: DashboardService,
    private chartService: ChartService,
    private surveyService: SurveyService,
    private authService: AuthService
  ) {
    this.loading.init = true;
    this.error = '';
    this.dateRange = { startDate: '', endDate: '' };
    this.period = DateDisplayHelper.getPeriodName(this.dateRange.startDate, this.dateRange.endDate);
    this.surveyName = dashboardService.surveyTitle;
    this.adminFilters = dashboardService.selectedDashboard.meta.adminFilters;
  }

  ngOnInit() {
    this.loading.init = true;
    this.buildTitleDisplayLookup();

    this.surveyService.questions.pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      this.questions = x;
      this.updateChart();
    });

    this.dashboardService.dateRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((range) => {
      this.dateRange = range;
      this.period = DateDisplayHelper.getPeriodName(this.dateRange.startDate, this.dateRange.endDate);

      this.dashboardService.responseRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((range) => {
        this.surveyResponseRange = range;
        let displayrange = DateDisplayHelper.getPeriodName(this.surveyResponseRange.startDate, '');
        this.surveyResponsePeriod = displayrange;
      });

      this.updateChart();
    });

    this.dashboardService.currentFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filters) => {
      this.updateChart();
    });

    this.loading.init = false;
    this.updateChart();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getDateRange(): DateRange {
    let dateRange = undefined;
    if (this.dateRange.startDate && this.dateRange.endDate) {
      dateRange = {
        startDate:
          moment(this.dateRange.startDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay,
        endDate: moment(this.dateRange.endDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay
      };
    } else {
      dateRange = {
        startDate: DateDisplayHelper.getBeginingOfPreviousMonth(),
        endDate: DateDisplayHelper.getEndOfPreviousMonth()
      };
    }

    return dateRange;
  }

  updateChart = () => {
    if (!this.loading.init) {
      this.loading.action = true;

      if (this.demographic.sources && this.demographic.sources.length > 0) {
        const payload = this.getPayloads();

        forkJoin(
          this.chartService.getDemographicData(
            payload['chartPayload'],
            this.dashboardService.getCurrentSurveyAccessCode()
          ),
          this.surveyService.getLabelsAndDatasets(
            payload['countPayload'],
            this.dashboardService.getCurrentSurveyAccessCode()
          ),
          this.surveyService.getLabelsAndDatasets(
            payload['rangePayload'],
            this.dashboardService.getCurrentSurveyAccessCode()
          )
        )
          .pipe(
            map(([chartData, count, rangeCount]) => {
              return { chartData, count, rangeCount };
            })
          )
          .subscribe(
            (response) => {
              this.data = response.chartData;

              this.allCount = response.count;
              this.rangeCount = response.rangeCount;

              this.loading.action = false;
            },
            (error) => {
              this.loading.action = false;
              this.error =
                'There was are error collecting summary data, please try again shortly. If the problem persists, please get in touch. ';
            }
          );
      } else {
        this.loading.action = false;
        this.error = 'Select a source question to create a demographics table';
      }

      this.loading.action = false;
    }
  };

  getPayloads() {
    let link = null;

    if (this.dashboardService.selectedDashboard.meta && this.dashboardService.selectedDashboard.meta.link) {
      link = this.dashboardService.selectedDashboard.meta.link;
    }

    const dateRange = this.getDateRange();
    const filters = this.dashboardService.getFilters();

    const fullRange = {
      startDate:
        moment(this.surveyResponseRange.startDate).format(DateDisplayHelper.dateFormat) +
        DateDisplayHelper.beginingOfDay,
      endDate: DateDisplayHelper.getEndOfPreviousMonth()
    };

    const payload = {
      periodRange: dateRange,
      fullRange,
      sources: this.demographic.sources,
      filters: filters,
      link: link,
      linkOptions: this.demographic.linkOptions
    };

    const countPayload = {
      type: 'count',
      config: {
        filters: filters,
        link: this.demographic.linkOptions.linkBaseLine ? link : null
      }
    };

    const rangePayload = {
      type: 'count',
      config: {
        filters: filters,
        dateRange: this.getDateRange(),
        link: this.demographic.linkOptions.linkRange ? link : null
      }
    };

    return {
      chartPayload: payload,
      countPayload: countPayload,
      rangePayload: rangePayload
    };
  }

  questionAdd(question: Question) {
    if (!this.demographic.sources) {
      this.demographic.sources = [];
    }
    this.demographic.sources.push(question);
    question.options.map((x) => (x.summaryDisplay = true));

    this.buildTitleDisplayLookup();

    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  }

  demographicsTitleEdit(demographic) {
    demographic.rename = !demographic.rename;
  }

  questionEdit(question: Question) {
    question.rename = !question.rename;
  }

  questionRemove(question: Question) {
    this.demographic.sources = this.demographic.sources.filter((item) => {
      return item.id !== question.id;
    });

    this.buildTitleDisplayLookup();

    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  }

  getQuestionTitle(question) {
    return QuestionTitleHelper.getQuestionTitle(question);
  }

  getOptionTitle(option: string) {
    return QuestionTitleHelper.getQuestionOptionTitle(option);
  }

  onSliderChange(option) {
    this.buildTitleDisplayLookup();
  }

  buildTitleDisplayLookup() {
    this.titleDisplayLookup = {};
    this.demographic.sources.map((x, i) => {
      let lowestShownOptionId = -1;

      x.options.map((y, j) => {
        if (y.summaryDisplay) {
          if (lowestShownOptionId === -1) {
            lowestShownOptionId = y.internalId;
          } else {
            lowestShownOptionId = Math.min(y.internalId, lowestShownOptionId);
          }
        }
        this.titleDisplayLookup[x.internalId] = lowestShownOptionId;
      });
    });
  }

  questionData = (questionId) => {
    if (this.data) {
      const data = this.data.find((item) => {
        return Number(item.questionId) === questionId;
      });
      if (data) {
        return data;
      }
    }
    return undefined;
  };

  findOption(question: Question, optionId: number) {
    if (question.options.length > 0) {
      const option = question.options.find((x) => x.internalId === optionId);
      if (option) {
        return option;
      }
    }

    return undefined;
  }

  delete() {
    if (confirm('Are you sure you want to delete this table?')) {
      this.dashboardService.removeDemographic(this.demographic);
    }
  }

  update() {
    this.dashboardService.saveSelectedDashboard();
    this.updateChart();
  }

  toggleMeasures(e, questions) {
    questions.forEach((q) => {
      q.summaryDisplay = e.checked;
    });
    this.dashboardService.saveSelectedDashboard();
  }

  copyTable() {
    const range = document.createRange();
    let id = 'demographicsTable-' + this.index;
    let element = document.getElementById(id);
    element.classList.add('export');
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    element.classList.remove('export');
  }

  loggedIn() {
    return this.authService.loggedIn;
  }
}
