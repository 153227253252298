import { Component } from '@angular/core';
import { DashboardService } from '@services/dashboard/dashboard.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  loading = false;

  constructor(
    public dashboardService: DashboardService,
  ) { }

  setOffset(offset) {
    this.dashboardService.offset = offset;
  }

}
