import { Component, OnInit } from '@angular/core';

import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { ClientService } from '@app/services/client/client.service';
import { SimpleModalService } from 'ngx-simple-modal';

import { DashboardAdminModalComponent } from '@components/dashboard-admin-modal/dashboard-admin-modal.component';
import { Dashboard, Client } from '@app/models/dashboard';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss']
})
export class AdminComponent implements OnInit {
  dashboards;

  surveys;
  surveys$;
  selectedSurvey;

  view = 'client';
  filter;

  loading = {
    clients: true,
    client: false,
    dashboards: true,
    filter: false
  };

  newClientName = 'Untitled';

  defaultClient = {
    id: -1,
    name: 'R-Outcomes',
    dashboards: []
  } as Client;

  clients: Client[];
  allDashboards: Dashboard[];

  private ngUnsubscribe = new Subject();

  constructor(private dashboardService: DashboardService, private clientService: ClientService, private simpleModalService: SimpleModalService) {}

  ngOnInit() {
    this.render();
  }

  render() {
    this.clientService.clients.pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.clients = x;
      this.loading.clients = false;

      this.clients.forEach(client => {
        if (!client.dashboards) {
          client.dashboards = [];
        }
        client.dashboards.sort((a, b) => a.name.localeCompare(b.name));
      });

      this.sortClients();

      if (!this.loading.dashboards) {
        this.setDefaultClient();
      }
    });

    this.dashboardService.allDashboards.pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.allDashboards = x;
      this.allDashboards.sort((a, b) => a.name.localeCompare(b.name));
      this.loading.dashboards = false;
      if (!this.loading.clients) {
        this.setDefaultClient();
      }
    });

    this.clientService.fetchAll();
    this.dashboardService.fetchAllDashboards();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setDefaultClient() {
    this.defaultClient.dashboards = [];

    const ownedDashboards = _.flatten(this.clients.map(client => client.dashboards));
    this.defaultClient.dashboards = _.xorWith(ownedDashboards, this.allDashboards, (a, b) => a.id === b.id);
  }

  selectSurvey(survey) {
    this.selectedSurvey = survey;
  }

  sortClients() {
    this.clients.sort((a, b) => {
      if (a.name === this.newClientName || b.name === this.newClientName || a.name === this.defaultClient.name || b.name === this.defaultClient.name) {
        if (a.name === this.newClientName && b.name === this.newClientName) {
          return b.id - a.id;
        }

        if (a.name === this.newClientName || b.name === this.defaultClient.name) {
          return -1;
        }

        if (b.name === this.newClientName || a.name === this.defaultClient.name) {
          return 1;
        }
      }

      return a.name.localeCompare(b.name);
    });
  }

  editDashboard(dashboard) {
    const modal = this.simpleModalService
      .addModal(DashboardAdminModalComponent, {
        mode: 'edit',
        title: 'Edit Dashboard',
        client: dashboard.client,
        buttonText: 'Save',
        dashboard: _.cloneDeep(dashboard)
      })
      .subscribe(result => {
        if (result) {
          dashboard = result;
          this.update();
        }
      });
  }

  duplicateDashboard(dashboard) {
    if (confirm('Are you sure you want to duplicate this dashboard?')) {
      delete dashboard.id;
      dashboard.name = dashboard.name + ' (copy)';
      dashboard.accessKey = this.dashboardService.regenerateAccessKey();
      this.dashboardService.create(dashboard).subscribe(response => {
        this.render();
      });
    }
  }

  newClient() {
    this.view = 'client';
    this.loading.client = true;
    this.clientService.create({ name: this.newClientName }).subscribe(client => {
      if (!client.dashboards) {
        client.dashboards = [];
      }
      this.clients.push(client);
      this.sortClients();
      this.loading.client = false;
    });
  }

  clientSave(client) {
    this.clientService.update(client).subscribe(response => {
      client.rename = false;
    });
  }

  newDashboard(client) {
    const modal = this.simpleModalService
      .addModal(DashboardAdminModalComponent, {
        mode: 'add',
        title: 'New Dashboard',
        client: client,
        buttonText: 'Create Dashboard',
        dashboard: new Dashboard()
      })
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.update();
        }
      });
  }

  update() {
    this.clientService.fetchAll();
    this.dashboardService.fetchAllDashboards();
  }

  setView(value, e) {
    e.preventDefault();
    this.view = value;
  }

  setFilter(value) {
    this.filter = value;
  }

  deleteClient(client) {
    if (confirm('Are you sure you want to remove this client?')) {
      this.clientService.delete(client).subscribe(x => {
        this.clients = this.clients.filter(c => c !== client);
      });
    }
  }

  deleteDashboard(dashboard) {
    if (confirm('Are you sure you want to delete this dashboard?')) {
      this.dashboardService.delete(dashboard).subscribe(x => {
        this.update();
      });
    }
  }
}
