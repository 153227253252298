import { Component, OnInit } from '@angular/core';
import { SurveyService } from '@app/services/survey/survey.service';

import { SimpleModalService } from 'ngx-simple-modal';
import { SurveyAdminModalComponent } from '@components/survey-admin-modal/survey-admin-modal.component';

@Component({
  selector: 'app-admin-surveys',
  templateUrl: './admin-surveys.component.html',
  styleUrls: ['./admin-surveys.component.scss']
})
export class AdminSurveysComponent implements OnInit {

  filter;

  loading = {
    init: true,
    filter: false,
  };

  surveys;

  constructor(
    private surveyService: SurveyService,
    private simpleModalService: SimpleModalService
  ) { }

  ngOnInit() {
    this.render();
  }

  render() {
    this.surveyService.getAll().subscribe(
      surveys => {
        this.surveys = surveys;
        this.loading.init = false;
      }
    );
  }

  createSurvey() {
    const modal = this.simpleModalService
      .addModal(SurveyAdminModalComponent, {
        mode: 'create',
        title: 'Create Survey',
        survey: {},
        buttonText: 'Submit',
      })

      .subscribe(result => {
        if (result) {
          this.render();
        }
      });
  }

  editSurvey(survey) {
    const modal = this.simpleModalService
      .addModal(SurveyAdminModalComponent, {
        mode: 'edit',
        title: 'Rename Survey',
        survey,
        buttonText: 'Save',
      })

      .subscribe(result => {
        if (result) {
          // console.log(result);
        }
      });
  }

  deleteSurvey(survey) {
    if (confirm('Are you sure you want to remove this survey?')) {
      this.surveyService.delete(survey).subscribe(x => {
        this.surveys = this.surveys.filter(s => s !== survey);
      });
    }
  }

  setFilter(value) {
    this.filter = value;
  }

}
