import {
  Component,
  Input,
  Output,
  EventEmitter
} from "@angular/core";

import { Tile, Filter } from "@models/dashboard";
import { DashboardService } from "@app/services/dashboard/dashboard.service";
import { SurveyService } from "@app/services/survey/survey.service";

@Component({
  selector: "app-tile-text-display",
  templateUrl: "./text.component.display.html",
  styleUrls: ["./text.component.display.scss"]
})
export class TileTextDisplayComponent {

  @Input() tile: Tile;
  @Output() update = new EventEmitter();

  constructor(
    public dashboardService: DashboardService,
    private surveyService: SurveyService
  ) {}

}
