import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-colour-palette',
  templateUrl: './colour-palette.component.html',
  styleUrls: ['./colour-palette.component.scss']
})
export class ColourPaletteComponent implements OnInit {
  @Input() palette: any;
  @Input() currentPalette: BehaviorSubject<any>;
  @Output() update = new EventEmitter();

  open = false;

  constructor() {}

  // tslint:disable: max-line-length
  public palettes = [
    { name: '1', codes: ['#4297a6', '#5DB0BE', '#bababa', '#878787', '#4d4d4d'] },
    { name: '2', codes: ['#EC7354', '#A8DADC', '#457B9D', '#F3BA37', '#A8847B', '#006837', '#E85E6C', '#5BC0BE', '#6FFFE9'] },
    { name: '3', codes: ['#2A9D8F', '#E9C46A', '#F4A261', '#E76F51', '#457B9D', '#A8DADC', '#E63946', '#8A6367'] },
    { name: '4', codes: ['#9BC53D', '#E55934', '#FA7921', '#247BA0', '#70C1B3', '#B2DBBF', '#F3FFBD', '#FF1654'] },
    { name: '5', codes: ['#E55934', '#EDAB4D', '#8A6367', '#27458C', '#E85E6C', '#F59451', '#66bd63', '#3288bd', '#5e4fa2', '#E63946'] },
    { name: '6', codes: ['#89B2ED', '#E87071', '#F49352', '#F3BA37', '#A8847B', '#D7B19C', '#3E8687', '#B8B2C2', '#CB4468', '#EB98AD'] },
    { name: '7', codes: ['#4E9CD0', '#60BBB6', '#A2B865', '#F7CA50', '#E99220', '#E35F26', '#C03C35', '#DD6977', '#93628D', '#355379', '#225358', '#327155', '#59BE8A', '#B5D77B', '#ffba08', '#ff7b00', '#D9334C', '#94365C', '#66165D' ,'#32375D'] },
    { name: '8', codes: ['#C03C35', '#E56B36', '#EDA546', '#F7CA50', '#A2B865', '#60BBB6', '#4E9CD0', '#355379', '#93628D', '#94365C', '#D9334C', '#DE7248', '#F2BF67', '#B5D77B', '#59BE8A', '#327155', '#225358', '#0C2D75', '#7647C2', '#C34BA1'] }
  ];

  ngOnInit() {
    this.currentPalette.subscribe(x => (this.palette = x));
  }

  select(palette: any) {
    this.palette = palette;
    this.update.emit(palette);
    $('#palette-container').collapse('toggle');
  }
}
