import { Component, OnInit } from '@angular/core';
import { Survey } from '@models/dashboard';
import { SimpleModalComponent } from 'ngx-simple-modal';

import { SurveyService } from '@app/services/survey/survey.service';

export interface SurveyModel {
  mode: string;
  title: string;
  survey: any;
  buttonText: string;
}

@Component({
  selector: 'app-survey-admin-modal',
  templateUrl: './survey-admin-modal.component.html',
  styleUrls: ['./survey-admin-modal.component.scss']
})
export class SurveyAdminModalComponent extends SimpleModalComponent<SurveyModel, Survey> implements OnInit {

  loading = false;

  mode: string;
  title = '';
  survey: Survey;
  buttonText: string;

  constructor(
    private surveyService: SurveyService) {
    super();
  }

  ngOnInit() {
  }

  submit() {

    this.loading = true;

    switch (this.mode) {

      case 'edit':
        this.surveyService.update(this.survey).subscribe(
          survey => {
            console.log('Updated!', survey);
            this.loading = false;
            this.result = survey;
            this.close();
          },
          err => {
            this.loading = false;
          }
        );
        break;

      case 'create':
        this.surveyService.create(this.survey).subscribe(
          survey => {
            console.log('Updated!', survey);
            this.loading = false;
            this.result = survey;
            this.close();
          },
          err => {
            this.loading = false;
          }
        );
        break;
    }

  }

  cancel() {
    this.close();
  }

}
