import { Component, Input, OnInit, OnChanges, ViewChild, Output, SimpleChanges, ElementRef } from '@angular/core';

import {
  Tile,
  Question,
  Filter,
  ChartConfig,
  DateRange,
  BarTileConfig,
  DataLabelDisplayOptions
} from '@models/dashboard';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotationsPlugin from 'chartjs-plugin-annotation';
import { Label, BaseChartDirective } from 'ng2-charts';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

import * as _ from 'lodash';
import { ChartService } from '@app/services/chart/chart.service';
import { ChartDisplayHelper } from '@app/helpers/chart.display.helper';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';

import { saveAs } from 'file-saver';
import { DateDisplayHelper } from '@app/helpers/date.display.helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var Chart: any;

@Component({
  selector: 'app-tile-bar-display',
  templateUrl: './bar.component.display.html',
  styleUrls: ['./bar.component.display.scss']
})
export class TileBarDisplayComponent implements OnInit, OnChanges {
  @Input() tile: Tile;
  @Input() filters: Filter[];
  @Output() loading: boolean;

  public question: Question;
  public data: any;
  public labels: Label[];
  public chartOptions: any;
  public chartType: string;
  public chartSuggestedMax: string;

  public config: BarTileConfig;

  private ngUnsubscribe = new Subject();

  initializing: boolean;

  dateRange: DateRange;
  surveyResponsePeriod: string;

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(public dashboardService: DashboardService, private chartService: ChartService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.tile && !changes.tile.isFirstChange()) || (changes.filters && !changes.filters.isFirstChange())) {
      this.render();
    }
  }

  ngOnInit() {
    this.initializing = true;

    this.config = this.tile.config as BarTileConfig;

    const backgroundColor = 'white';
    Chart.pluginService.register({
      beforeDraw(c) {
        const ctx = c.chart.ctx;
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      }
    });

    this.chartOptions = {
      title: {
        // text: this.tile ? this.tile.title : 'asdasdasd',
        display: true,
        fontFamily: 'Lato',
        fontSize: 14,
        text: [this.tile.title]
      },
      hover: { mode: null },
      legend: {
        // boxWidth: 100,
        padding: 100,
        labels: {
          usePointStyle: true,
          padding: 20
        }
      },
      layout: {
        padding: {
          right: 40,
          bottom: 10
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 5,
          hitRadius: 5,
          hoverRadius: 7,
          hoverBorderWidth: 2
        }
      },
      scales: {},
      plugins: {},
      annotation: {}
    };

    this.dashboardService.dateRange.subscribe((range) => {
      this.dateRange = range;
      this.render();
    });

    this.dashboardService.responseRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((range) => {
      let displayrange = DateDisplayHelper.getPeriodName(range.startDate, range.endDate);
      this.surveyResponsePeriod = displayrange;
    });

    this.initializing = false;
    this.render();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  download(tile) {
    this.canvas.nativeElement.toBlob((blob) => {
      saveAs(blob, Date.now() + '-' + tile.id);
    });
  }

  render() {
    if (!this.initializing) {
      this.loading = true;

      this.chartOptions.legend.display = this.config.showLegend;
      this.chartOptions.legend['position'] = 'top'; // this.tile.config['legendPosition'];
      this.chartOptions.legend.labels.boxWidth = this.config.groupBy ? 40 : 0;

      this.chartType = this.config.isVertical ? 'bar' : 'horizontalBar';

      this.chartSuggestedMax = this.config.suggestedMax ? '100' : '0';

      // set x Axes
      this.chartOptions.scales.xAxes = [
        {
          id: 'x-axis-0',
          position: 'left',
          ticks: {
            beginAtZero: true,
            suggestedMax: this.chartSuggestedMax
          },
          gridLines: {
            drawOnChartArea: this.tile.config['showGridLinesX'],
            lineWidth: 2,
            color: 'rgba(0,0,0,0.15)'
          }
        }
      ];

      // set y Axes
      this.chartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            beginAtZero: true,
            suggestedMax: this.chartSuggestedMax
          },
          gridLines: {
            drawOnChartArea: this.tile.config['showGridLinesY'],
            lineWidth: 2,
            color: 'rgba(0,0,0,0.15)'
          }
        }
      ];
      let axisTitle =
        this.config.dataLabelDisplayOption === DataLabelDisplayOptions.percentage
          ? 'Overall percentage'
          : 'Number of responses';

      if (this.tile.config['sources'].length > 1 || this.tile.config['sourcesGroupsOnly']) {
        axisTitle =
          this.config.dataLabelDisplayOption === DataLabelDisplayOptions.percentage
            ? 'Overall percentage'
            : 'Mean Score';
      }

      if (this.chartType === 'bar') {
        this.chartOptions.scales.yAxes[0].scaleLabel = {
          display: true,
          labelString: axisTitle,
          fontFamily: 'Lato',
          fontSize: 14,
          fontStyle: 'bold',
          padding: 10
        };
      } else {
        this.chartOptions.scales.xAxes[0].scaleLabel = {
          display: true,
          labelString: axisTitle,
          fontFamily: 'Lato',
          fontSize: 14,
          fontStyle: 'bold',
          padding: 10
        };
      }

      const dataLabelsDecimalPlaces = this.tile.config['dataLabelsDecimalPlaces'];
      const dataLabelDisplayOption = this.tile.config['dataLabelDisplayOption'];

      if (
        this.tile.config['showScoreBaselines'] === true &&
        (this.tile.config['sources'].length > 1 || this.tile.config['groupBy'])
      ) {
        this.chartOptions.annotation = ChartDisplayHelper.getConfidenceLines(this.tile.config);
      } else {
        this.chartOptions.annotation = {};
      }

      this.chartOptions.plugins = {
        ChartAnnotationsPlugin,
        ChartDataLabels,
        datalabels: {
          display: this.tile.config['showDataLabels'],
          anchor: 'end',
          align: this.tile.config['isVertical'] ? 'top' : 'right',
          offset: 5,
          formatter: (value, chart) => {
            let dataLabelDisplay = '';
            if (dataLabelDisplayOption === 'Percentage') {
              dataLabelDisplay = value.toFixed(dataLabelsDecimalPlaces) + '%';
            } else {
              dataLabelDisplay = value !== 0 && value ? value.toFixed(dataLabelsDecimalPlaces) : value;
            }
            return dataLabelDisplay;
          }
        }
      };

      if ((this.tile.config as ChartConfig).sources.length > 0) {
        const adminFilters = this.dashboardService.selectedDashboard.meta.adminFilters;

        let allfilters;
        if (adminFilters && this.filters) {
          allfilters = adminFilters.concat(this.filters);
        } else if (adminFilters) {
          allfilters = adminFilters;
        } else if (this.filters) {
          allfilters = this.filters;
        }

        const payload = ChartDisplayHelper.getPayload(this.tile.config, allfilters, this.tile.type, this.dateRange);

        this.chartService.getChartData(payload, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(
          (response) => {
            if (response) {
              this.data = [];

              if (response.data) {
                if (this.tile.config['groupBy']) {
                  response.data.map((series, index) => {
                    let value = series.data;
                    const valueSum = value.reduce((a, b) => a + b, 0);
                    if (valueSum > 0 || this.tile.config['displayZeroSeries']) {
                      if (dataLabelDisplayOption === 'Percentage') {
                        let sum = response.data.map((x) => x.responseCount).reduce((a, b) => a + b, 0);
                        value = series.data.map((x) => (x * 100) / sum);
                      }
                      this.data.push(
                        this.getChartJsData(
                          QuestionTitleHelper.getQuestionOptionTitle(series.label),
                          value,
                          series.responseCount,
                          this.dashboardService.getColourPalette().codes[index]
                        )
                      );
                    }
                  });
                } else if (this.tile.config['sources'].length > 1) {
                  let value = response.data;
                  if (dataLabelDisplayOption === 'Percentage') {
                    value = response.data.map((x) => (x * 100) / response.responseCount);
                  }
                  this.data.push(
                    this.getChartJsData(
                      '',
                      value,
                      response.responseCount,
                      this.dashboardService.getColourPalette().codes.slice(0, response.data.length)
                    )
                  );
                } else {
                  let value = response.data;
                  if (dataLabelDisplayOption === 'Percentage') {
                    let sum = response.data.reduce((a, b) => a + b, 0);
                    value = response.data.map((x) => (x * 100) / sum);
                  }

                  if (!this.tile.config['displayZeroSeries']) {
                    for (let i = value.length - 1; i >= 0; i--) {
                      let v = value[i];
                      if (v === 0) {
                        value.splice(i, 1);
                        response.labels.splice(i, 1);
                      }
                    }
                  }
                  this.data.push(
                    this.getChartJsData(
                      '',
                      value,
                      response.responseCount,
                      this.dashboardService.getColourPalette().codes[0]
                    )
                  );
                }

                let splitLabels = [];

                if (response.labels && response.labels.length > 0) {
                  response.labels.map((label) => {
                    splitLabels.push(this.splitLabel(label));
                  });

                  this.labels = splitLabels;
                }
              }

              if (ChartDisplayHelper.isMycawScoresChart((this.tile.config as ChartConfig).sources)) {
                this.chartOptions.scales.xAxes[0].ticks.suggestedMax = 6;
                this.chartOptions.scales.yAxes[0].ticks.suggestedMax = 6;
              } else {
                this.chartOptions.scales.xAxes.stepSize = 20;
              }

              let dateRange: string;
              if (this.dateRange.startDate) {
                dateRange = DateDisplayHelper.getPeriodName(this.dateRange.startDate, this.dateRange.endDate);
              } else {
                dateRange = this.surveyResponsePeriod;
              }

              this.chartOptions.title.text = [this.tile.title, dateRange];
            }

            // hack for when data comes back empty
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      } else {
        this.loading = false;
      }
    }
  }

  // If we did this on label-draw we might be
  // able to lower the limit to prevent label
  // rotation
  splitLabel(label: string) {
    if (!label) {
      return label;
    }

    const limit = this.config.charactersPerLine ? parseFloat(this.config.charactersPerLine) : 40;
    let cleanLabel = QuestionTitleHelper.getQuestionOptionTitle(label);
    const words = cleanLabel.split(' ');
    let splitLabel = [];
    let concat = [];

    for (let i = 0; i < words.length; i++) {
      concat.push(words[i]);
      let join = concat.join(' ');
      if (join.length > limit) {
        splitLabel.push(join);
        concat = [];
      }
    }

    if (concat.length) {
      splitLabel.push(concat.join(' ').trim());
    }

    return splitLabel;
  }

  getChartJsData(label: string, data: any, seriesCount: number, itemColour: any) {
    let enrichedLabel;

    if (seriesCount) {
      enrichedLabel = `${label} (n=${seriesCount})`;
    } else {
      enrichedLabel = label;
    }

    if (this.dashboardService.selectedDashboard.meta.palette.codes) {
      return {
        label: enrichedLabel,
        data: data,
        backgroundColor: itemColour,
        borderWidth: 1,
        borderColor: itemColour,
        showLine: false,
        pointBackgroundColor: itemColour
      };
    } else {
      return {
        label: enrichedLabel,
        data: data
      };
    }
  }
}
