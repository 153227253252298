import { Component, Input, OnInit, OnChanges, ViewChild, Output, SimpleChanges } from '@angular/core';

import { Tile, Question, Dashboard, Filter, DateRange } from '@models/dashboard';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Label, BaseChartDirective } from 'ng2-charts';
import { ChartService } from '@app/services/chart/chart.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

import * as _ from 'lodash';
import { ChartDisplayHelper } from '@app/helpers/chart.display.helper';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';

@Component({
  selector: 'app-tile-line-display',
  templateUrl: './line.component.display.html',
  styleUrls: ['./line.component.display.scss']
})
export class TileLineDisplayComponent implements OnInit, OnChanges {
  @Input() tile: Tile;
  @Input() filters: Filter[];
  @Output() loading: boolean;
  public question: Question;
  public data: any;
  public datasets: any;
  public labels: Label[];
  public chartOptions: any;
  public chartType: string;

  dateRange: DateRange;

  initializing: boolean;

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(public dashboardService: DashboardService, private chartService: ChartService) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.tile && !changes.tile.isFirstChange()) || (changes.filters && !changes.filters.isFirstChange())) {
      this.render();
    }
  }

  ngOnInit() {
    this.initializing = true;
    this.chartOptions = {
      title: {
        // display: true,
        text: this.tile ? this.tile.title : ''
      },
      hover: { mode: null },
      legend: {
        labels: {
          usePointStyle: true,
          padding: 20
        }
      },
      layout: {
        padding: {
          top: 30,
          right: 30
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 5,
          hitRadius: 5,
          hoverRadius: 7,
          hoverBorderWidth: 2
        },
        line: {
          fill: false
        }
      },
      scales: {},
      plugins: {},
      annotation: {}
    };

    this.dashboardService.dateRange.subscribe(range => {
      this.dateRange = range;
      this.render();
    });

    this.dashboardService.currentFilters.subscribe(filters => {
      this.render();
    });

    this.initializing = false;
    this.render();
  }

  render() {
    if (!this.initializing) {
      this.loading = true;

      this.chartOptions.legend.display = this.tile.config['showLegend'];
      this.chartOptions.legend['position'] = this.tile.config['legendPosition'];

      this.chartType = 'line';

      this.chartOptions.scales.xAxes = [
        {
          id: 'x-axis-0',
          position: 'left',
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            display: this.tile.config['showGridLinesX']
          }
        }
      ];

      this.chartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            display: this.tile.config['showGridLinesY']
          }
        }
      ];

      const dataLabelsDecimalPlaces = this.tile.config['dataLabelsDecimalPlaces'];

      this.chartOptions.plugins = {
        ChartDataLabels,
        datalabels: {
          display: this.tile.config['showDataLabels'],
          anchor: 'end',
          align: 'top',
          formatter(value) {
            const fixedDecimalValue = value !== 0 && value ? value.toFixed(dataLabelsDecimalPlaces) : value;
            return fixedDecimalValue;
          }
        }
      };
      const adminFilters = this.dashboardService.selectedDashboard.meta.adminFilters;

      const payload = ChartDisplayHelper.getPayload(this.tile.config, this.filters.concat(adminFilters), this.tile.type, this.dateRange);

      this.chartService.getChartData(payload, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(
        response => {
          if (response) {
            this.data = [];
            if (this.tile.config['groupBy']) {
              response.data.map((series, index) => {
                this.data.push(
                  this.getChartJsData(
                    QuestionTitleHelper.getQuestionOptionTitle(series.label),
                    series.data,
                    series.responseCount,
                    this.dashboardService.getColourPalette().codes[index]
                  )
                );
              });
            } else if (this.tile.config['sources'].length > 1) {
              this.data.push(
                this.getChartJsData('', response.data, response.responseCount, this.dashboardService.getColourPalette().codes.slice(0, response.data.length))
              );
            } else {
              this.data.push(this.getChartJsData('', response.data, response.responseCount, this.dashboardService.getColourPalette().codes[0]));
            }

            let splitLabels = [];

            if (response.labels && response.labels.length > 0) {
              response.labels.map(label => {
                splitLabels.push(this.splitLabel(label));
              });

              this.labels = splitLabels;
            }
          }

          this.chartOptions.scales.xAxes[0].ticks.suggestedMax = Math.ceil(response.highestValue / 10) * 10;

          // hack for when data comes back empty
          this.loading = false;
        },
        error => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  splitLabel(label: string) {
    if(!label){
      return label;
    }
    const limit = 12;
    let cleanLabel = QuestionTitleHelper.getQuestionOptionTitle(label);
    const words = cleanLabel.split(' ');
    let splitLabel = [];
    let concat = [];

    for (let i = 0; i < words.length; i++) {
      concat.push(words[i]);
      let join = concat.join(' ');
      if (join.length > limit) {
        splitLabel.push(join);
        concat = [];
      }
    }

    if (concat.length) {
      splitLabel.push(concat.join(' ').trim());
    }

    return splitLabel;
  }

  getChartJsData(label: string, data: any, seriesCount: number, itemColour: any) {
    let enrichedLabel;

    if (seriesCount) {
      enrichedLabel = `${label} (${seriesCount})`;
    } else {
      enrichedLabel = label;
    }

    if (this.dashboardService.selectedDashboard.meta.palette.codes) {
      return {
        label: enrichedLabel,
        data: data,
        backgroundColor: itemColour,
        borderWidth: 3,
        borderColor: itemColour,
        showLine: true,
        pointBackgroundColor: itemColour
      };
    } else {
      return {
        label: enrichedLabel,
        data: data
      };
    }
  }
}
