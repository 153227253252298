import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadService } from '@services/upload/upload.service';
import { OnInit, Component, ViewChild, ElementRef, Input } from '@angular/core';
import { Client } from '@app/models/dashboard';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  form: FormGroup;
  error: string;
  @Input() client: Client;
  uploadResponse = { status: '', message: '', filePath: '' };

  @ViewChild('file', { static: false }) file: ElementRef;

  constructor(private formBuilder: FormBuilder, private uploadService: UploadService) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      avatar: ['']
    });
  }

  open(e) {
    e.stopPropagation();
    this.file.nativeElement.click();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0] as File;

      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);

      this.uploadService.upload(formData, this.client.id).subscribe(
        res => {
          this.uploadResponse = res;
          this.client.logoFileName = res.logoFileName;
          // console.log(this.uploadResponse);
        },
        err => (this.error = err)
      );
    }
  }

  getEnvironment() {
    return environment.assetUrl;
  }

  onSubmit() {}
}
