import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DashboardService} from '@services/dashboard/dashboard.service';
import {ChartService} from '@services/chart/chart.service';

import * as moment from 'moment';
import _ from 'lodash';

import {DateRange, Filter, LinkOptions, Question, Summary, SummaryPayload} from '../../../models/dashboard';
import {SurveyService} from '@app/services/survey/survey.service';
import {DateDisplayHelper} from '@app/helpers/date.display.helper';
import {QuestionTitleHelper} from '@app/helpers/question.title.helper';
import {AuthService} from '@app/auth/auth.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Input() summary: Summary;
  @Output() summaryChange: EventEmitter<Summary> = new EventEmitter<Summary>();

  data;
  payload: SummaryPayload;
  questions: Question[];

  dateRange: DateRange;
  surveyResponseRange: DateRange;

  notes: [
    {
      sourceId: number;
      text: string;
    }
  ];

  loading = {
    init: false,
    action: false
  };
  error: string;

  period: string;
  surveyResponsePeriod: string;
  surveyName: string;
  adminFilters: Filter[];
  numberOfDataColumns: number[];
  hasLinkedData: boolean;

  initializing: boolean;

  dataView;
  linkedDataToggle: LinkOptions = new LinkOptions();
  linkedDataView: boolean;

  private ngUnsubscribe = new Subject();

  constructor(
    public dashboardService: DashboardService,
    private chartService: ChartService,
    private surveyService: SurveyService,
    private authService: AuthService
  ) {
    this.loading.init = true;
    this.error = '';
    this.dateRange = {startDate: '', endDate: ''};
    this.period = DateDisplayHelper.getPeriodName(this.dateRange.startDate, this.dateRange.endDate);
    this.surveyName = dashboardService.surveyTitle;
    this.adminFilters = dashboardService.selectedDashboard.meta.adminFilters;
    this.hasLinkedData = dashboardService.selectedDashboard.meta.link ? true : false;
  }

  ngOnInit() {
    this.initializing = true;
    this.numberOfDataColumns = [];

    if (!this.summary) {
      this.summary = new Summary();
    }

    this.dataView = 'Default Data';
    this.linkedDataToggle.linkBaseLine = this.summary.linkOptions.linkBaseLine;
    this.linkedDataToggle.linkRange = this.summary.linkOptions.linkRange;

    this.dateRange = {startDate: '', endDate: ''};
    this.surveyResponsePeriod = null;

    this.surveyService.questions.pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.questions = x;
      this.updateChart();
    });

    this.dashboardService.dateRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(range => {
      this.dateRange = range;
      this.period = DateDisplayHelper.getPeriodName(this.dateRange.startDate, this.dateRange.endDate);

      this.dashboardService.responseRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(range => {
        this.surveyResponseRange = range;
        let displayRange;

        if (this.dashboardService.selectedDashboard.meta.fullRange) {
          displayRange = DateDisplayHelper.getPeriodName(this.dashboardService.selectedDashboard.meta.fullRange.startDate, this.dashboardService.selectedDashboard.meta.fullRange.endDate);
        } else {
          displayRange = DateDisplayHelper.getPeriodName(this.surveyResponseRange.startDate, '');
        }

        this.surveyResponsePeriod = displayRange;
      });

      this.updateChart();
    });

    this.dashboardService.currentFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe(filters => {
      this.updateChart();
    });

    if (this.summary.seriesQuestion && this.summary.seriesQuestion.options) {
      this.summary.seriesQuestion.options = this.summary.seriesQuestion.options.sort((a, b) => a.internalId - b.internalId);
    }

    this.initializing = false;
    this.updateChart();
  }

  public isIssueQuestion(question: Question): boolean {
    return question.alias === 'IssScore';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateChart = () => {
    if (!this.initializing) {
      this.loading.init = true;
      if (this.summary.measurementSources && this.summary.measurementSources.length > 0) {
        let dateRange = undefined;
        if (this.dateRange.startDate && this.dateRange.endDate) {
          dateRange = {
            startDate: moment(this.dateRange.startDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay,
            endDate: moment(this.dateRange.endDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay
          };
        } else {
          dateRange = {
            startDate: DateDisplayHelper.getBeginingOfPreviousMonth(),
            endDate: DateDisplayHelper.getEndOfPreviousMonth()
          };
        }

        let link = null;

        if (this.dashboardService.selectedDashboard.meta && this.dashboardService.selectedDashboard.meta.link) {
          link = this.dashboardService.selectedDashboard.meta.link;
        }
        let groupQuestion = null;

        if (this.summary.seriesQuestion) {
          groupQuestion = _.clone(this.summary.seriesQuestion);
        }

        let fullRange = {
          startDate: moment(this.surveyResponseRange.startDate).format(DateDisplayHelper.dateFormat) + DateDisplayHelper.beginingOfDay,
          endDate: DateDisplayHelper.getEndOfPreviousMonth()
        };

        if (this.dashboardService.selectedDashboard.meta.fullRange) {
          fullRange = this.dashboardService.selectedDashboard.meta.fullRange;
        }

        // link property - must not be null/empty in order to display link toggle
        const payload = {
          periodRange: dateRange,
          fullRange: fullRange,
          groupQuestion: groupQuestion,
          groupQuestionOptions: this.summary.seriesQuestionOptions,
          sources: this.summary.measurementSources,
          filters: this.dashboardService.getFilters(),
          link,
          linkOptions: this.linkedDataToggle, //this.linkedDataToggle ? this.linkedDataToggle : this.summary.linkOptions // object in dashboard.ts
          requireCompleteResponses: this.summary.requireFullMeasureResponse
        };

        let optionIndexes = [];

        if (this.summary.seriesQuestion && this.summary.seriesQuestionOptions) {
          optionIndexes = this.getSelectedContactTypeIndexes();
        }

        this.chartService.getSummaryData(payload, optionIndexes, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(
          response => {
            this.data = response;

            this.numberOfDataColumns = [];
            if (this.data[0]) {
              this.numberOfDataColumns = new Array(this.data[0].data.length).fill(0);
            }

            this.loading.init = false;
          },
          error => {
            this.loading.init = false;
            this.error = 'There was are error collecting summary data, please try again shortly. If the problem persists, please get in touch. ';
          }
        );
      } else {
        this.loading.init = false;
        this.error =
          'This survey does not have a question with the alias \'Session\'. As a result, summary data cannot be presented. To resolve the issue, add the alias to the appropriate question in your survey.';
        this.data = null;
      }
    }
  };

  getSelectedContactTypeIndexes() {
    if (Object.values(this.summary.seriesQuestion).length > 0 && this.summary.seriesQuestion.options) {
      return this.summary.seriesQuestion.options
        .sort((a, b) => a.internalId - b.internalId)
        .map((contactType, index) => {
          if (this.summary.seriesQuestionOptions.find(({internalId}) => contactType.internalId === internalId)) {
            return index;
          }
          return -1;
        })
        .filter(contactTypeIndex => contactTypeIndex !== -1);
    }
  }

  questionAdd = (question: Question) => {
    this.summary.measurementSources.push(question);
    question.summaryDisplay = true;
    question.subQuestions.map(x => (x.summaryDisplay = true));
    question.subQuestions.sort((a, b) => a.internalId - b.internalId);

    this.summaryChange.emit(this.summary);
    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  };

  summaryTitleEdit(summary) {
    summary.rename = !summary.rename;
  }

  questionEdit(question) {
    question.rename = !question.rename;
  }

  questionRemove = (question: Question) => {
    this.summary.measurementSources = this.summary.measurementSources.filter(item => {
      return item.id !== question.id;
    });
    this.summaryChange.emit(this.summary);
    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  };

  groupQuestionAdd = (question: Question) => {
    this.summary.seriesQuestionOptions = [];
    this.summary.seriesQuestion = question;
    this.summary.seriesQuestion.options = this.summary.seriesQuestion.options.sort((a, b) => a.internalId - b.internalId);
    this.summaryChange.emit(this.summary);
    this.updateChart();
    this.dashboardService.saveSelectedDashboard();
  };

  questionData = (question: Question) => {
    if (this.data) {
      const data = this.data.find(item => {
        return Number(item.questionId) === question.id;
      });
      if (data) {
        return data.data;
      }
    }

    return undefined;
  };

  optionsChange = () => {
    this.update();
  };

  // getGroupAnswerTitle(data: any) {
  //   let title = 'Title';
  //   if (this.summary.seriesQuestion && this.summary.seriesQuestion.options.length > 0 && data.answerId) {
  //     const found = this.summary.seriesQuestion.options.find(option => option.internalId === data.answerId);
  //
  //     if (found) {
  //       title = QuestionTitleHelper.getQuestionTitle(found);
  //     }
  //   }
  //   return title;
  // }

  getQuestionTitle(question) {
    return QuestionTitleHelper.getQuestionTitle(question);
  }

  getTdClass(value: number) {
    const rounded = Math.round(value);
    if (rounded == 0) {
      return '';
    }
    if (rounded < 40) {
      return 'vlow';
    }
    if (rounded < 60) {
      return 'low';
    }
    if (rounded < 80) {
      return 'moderate';
    }
    return 'high';
  }

  getSubQuestionResult(data, subQuestion) {
    return data.subResults.find(x => x.question.internalId === subQuestion.internalId);
  }

  reset() {
    this.summary.seriesQuestion = null;
    this.summaryChange.emit(this.summary);
    this.dashboardService.saveSelectedDashboard();
    this.updateChart();
  }

  save() {
    this.dashboardService.saveSelectedDashboard();
  }

  update() {
    this.summaryChange.emit(this.summary);
    this.dashboardService.saveSelectedDashboard();
    this.updateChart();
  }

  setContactTypes(types) {
    // console.log(types);
  }

  setDataView(view) {
    switch (view) {
      case 'linked':
        this.dataView = 'Linked Data';
        this.linkedDataToggle.linkBaseLine = true;
        this.linkedDataToggle.linkRange = true;
        break;
      case 'unlinked':
        this.dataView = 'Unlinked Data';
        this.linkedDataToggle.linkBaseLine = false;
        this.linkedDataToggle.linkRange = false;
        break;
      case 'reset':
        this.dataView = 'Default Data';
        this.linkedDataToggle.linkBaseLine = this.summary.linkOptions.linkBaseLine;
        this.linkedDataToggle.linkRange = this.summary.linkOptions.linkRange;
        break;
    }
    console.log(this.linkedDataToggle);
    this.updateChart();
  }

  toggleLinkedData(e) {

    if (!this.linkedDataToggle) {
      this.linkedDataToggle = new LinkOptions();
    }

    this.linkedDataToggle.linkBaseLine = e.checked;
    this.linkedDataToggle.linkRange = e.checked;
    console.log(this.linkedDataToggle);
    this.updateChart();
  }

  toggleMeasures(e, questions) {
    questions.forEach(q => {
      q.summaryDisplay = e.checked;
    });
    this.save();
  }

  copySummary() {
    const range = document.createRange();
    let element = document.getElementById('summaryTables');
    element.classList.add('export');
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    element.classList.remove('export');
  }

  loggedIn() {
    return this.authService.loggedIn;
  }
}
