import { Component, Input, OnInit, OnChanges, ViewChild, Output, SimpleChanges, ElementRef } from '@angular/core';

import { Tile, Question, Filter, DateRange, TimelineTileConfig, DataLabelDisplayOptions } from '@models/dashboard';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotationsPlugin from 'chartjs-plugin-annotation';
import { Label, BaseChartDirective } from 'ng2-charts';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

import * as _ from 'lodash';
import { ChartService } from '@app/services/chart/chart.service';
import { ChartDisplayHelper } from '@app/helpers/chart.display.helper';
import { QuestionTitleHelper } from '@app/helpers/question.title.helper';

import { saveAs } from 'file-saver';
import { DateDisplayHelper } from '@app/helpers/date.display.helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

declare var Chart: any;

@Component({
  selector: 'app-tile-timeline-display',
  templateUrl: './timeline.component.display.html',
  styleUrls: ['./timeline.component.display.scss'],
  providers: [DatePipe]
})
export class TileTimelineDisplayComponent implements OnInit, OnChanges {
  @Input() tile: Tile;
  @Input() filters: Filter[];
  @Output() loading: boolean;

  public question: Question;
  public data: any;
  public labels: Label[];
  public chartOptions: any;
  public chartType: string;

  public config: TimelineTileConfig;

  private ngUnsubscribe = new Subject();

  initializing: boolean;

  dateRange: DateRange;
  surveyResponsePeriod: string;

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(public dashboardService: DashboardService, private chartService: ChartService, private datePipe: DatePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.tile && !changes.tile.isFirstChange()) || (changes.filters && !changes.filters.isFirstChange())) {
      this.render();
    }
  }

  ngOnInit() {
    this.initializing = true;

    this.config = this.tile.config as TimelineTileConfig;

    const backgroundColor = 'white';
    Chart.pluginService.register({
      beforeDraw(c) {
        const ctx = c.chart.ctx;
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, c.chart.width, c.chart.height);
      }
    });

    this.chartOptions = {
      title: {
        // text: this.tile ? this.tile.title : 'asdasdasd',
        display: true,
        fontFamily: 'Lato',
        fontSize: 14,
        text: [this.tile.title]
      },
      hover: { mode: null },
      legend: {
        // boxWidth: 100,
        padding: 100,
        labels: {
          usePointStyle: true,
          padding: 20
        }
      },
      layout: {
        padding: {
          right: 40,
          bottom: 10
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 5,
          hitRadius: 5,
          hoverRadius: 7,
          hoverBorderWidth: 2
        }
      },
      scales: {},
      plugins: {},
      annotation: {}
    };

    this.dashboardService.dateRange.subscribe(range => {
      this.dateRange = range;
      this.render();
    });

    this.dashboardService.responseRange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(range => {
      let displayrange = DateDisplayHelper.getPeriodName(range.startDate, range.endDate);
      this.surveyResponsePeriod = displayrange;
    });

    this.dashboardService.currentFilters.subscribe(filters => {
      this.render();
    });

    this.initializing = false;
    this.render();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  download(tile) {
    this.canvas.nativeElement.toBlob(blob => {
      saveAs(blob, Date.now() + '-' + tile.id);
    });
  }

  render() {
    if (!this.initializing) {
      this.loading = true;

      // console.log(this.config);

      this.chartOptions.legend.display = this.config.showLegend;
      this.chartOptions.legend['position'] = 'top';
      this.chartOptions.legend.labels.boxWidth = this.config.groupBy ? 40 : 0;

      this.chartType = this.config.isVertical ? 'bar' : 'horizontalBar';

      // set x Axes
      this.chartOptions.scales.xAxes = [
        {
          id: 'x-axis-0',
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            drawOnChartArea: this.tile.config['showGridLinesX'],
            lineWidth: 2,
            color: 'rgba(0,0,0,0.25)'
          }
        }
      ];

      // set y Axes
      this.chartOptions.scales.yAxes = [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            drawOnChartArea: this.tile.config['showGridLinesY'],
            lineWidth: 2,
            color: 'rgba(0,0,0,0.25)'
          }
        }
      ];
      const axisTitle = 'Number of responses';

      if (this.chartType === 'bar') {
        this.chartOptions.scales.yAxes[0].scaleLabel = {
          display: true,
          labelString: axisTitle,
          fontFamily: 'Lato',
          fontSize: 14,
          fontStyle: 'bold',
          padding: 10
        };
      } else {
        this.chartOptions.scales.xAxes[0].scaleLabel = {
          display: true,
          labelString: axisTitle,
          fontFamily: 'Lato',
          fontSize: 14,
          fontStyle: 'bold',
          padding: 10
        };
      }

      const dataLabelsDecimalPlaces = this.tile.config['dataLabelsDecimalPlaces'];
      const dataLabelDisplayOption = this.tile.config['dataLabelDisplayOption'];

      if (this.tile.config['showScoreBaselines'] === true && (this.tile.config['sources'].length > 1 || this.tile.config['groupBy'])) {
        this.chartOptions.annotation = ChartDisplayHelper.getConfidenceLines(this.tile.config);
      } else {
        this.chartOptions.annotation = {};
      }

      this.chartOptions.plugins = {
        ChartAnnotationsPlugin,
        ChartDataLabels,
        datalabels: {
          display: this.tile.config['showDataLabels'],
          anchor: 'end',
          align: this.tile.config['isVertical'] ? 'top' : 'right',
          offset: 5,
          formatter: (value, chart) => {
            let dataLabelDisplay = '';
            if (dataLabelDisplayOption === 'Percentage') {
              dataLabelDisplay = value.toFixed(dataLabelsDecimalPlaces) + '%';
            } else {
              dataLabelDisplay = value !== 0 && value ? value.toFixed(dataLabelsDecimalPlaces) : value;
            }
            return dataLabelDisplay;
          }
        }
      };

      const adminFilters = this.dashboardService.selectedDashboard.meta.adminFilters;

      let allfilters;
      if (adminFilters && this.filters) {
        allfilters = adminFilters.concat(this.filters);
      } else if (adminFilters) {
        allfilters = adminFilters;
      } else if (this.filters) {
        allfilters = this.filters;
      }

      let selectedDateRange = null;

      if (this.dateRange && this.dateRange.startDate !== '' && this.dateRange.endDate !== '') {
        selectedDateRange = this.dateRange;
      }

      const payload = {
        type: 'timeline',
        config: {
          filters: allfilters,
          dateRange: selectedDateRange
        }
      };

      // console.log('payload: ', payload);

      this.chartService.getChartData(payload, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(
        response => {
          if (response) {
            this.data = [];
            this.data.push(this.getChartJsData('', response.data, response.responseCount, this.dashboardService.getColourPalette().codes[0]));
            this.labels = response.labels.map(label => {
              return this.datePipe.transform(label, 'MMM y');
            });

            this.loading = false;
          }
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  // If we did this on label-draw we might be
  // able to lower the limit to prevent label
  // rotation
  splitLabel(label: string) {
    if(!label){
      return label;
    }
    const limit = 40;
    let cleanLabel = QuestionTitleHelper.getQuestionOptionTitle(label);
    const words = cleanLabel.split(' ');
    let splitLabel = [];
    let concat = [];

    for (let i = 0; i < words.length; i++) {
      concat.push(words[i]);
      let join = concat.join(' ');
      if (join.length > limit) {
        splitLabel.push(join);
        concat = [];
      }
    }

    if (concat.length) {
      splitLabel.push(concat.join(' ').trim());
    }

    return splitLabel;
  }

  getChartJsData(label: string, data: any, seriesCount: number, itemColour: any) {
    let enrichedLabel;

    if (seriesCount) {
      enrichedLabel = `${label} (n=${seriesCount})`;
    } else {
      enrichedLabel = label;
    }

    if (this.dashboardService.selectedDashboard.meta.palette.codes) {
      return {
        label: enrichedLabel,
        data: data,
        backgroundColor: itemColour,
        borderWidth: 3,
        borderColor: itemColour,
        pointRadius: 7,
        showLine: true,
        fill: false,
        pointBackgroundColor: itemColour
      };
    } else {
      return {
        label: enrichedLabel,
        data: data
      };
    }
  }
}
