import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { AuthService } from '@app/auth/auth.service';
import { SimpleModalService } from 'ngx-simple-modal';

import { Tile, Dashboard } from '@models/dashboard';
import { EditorModalComponent } from '@components/editor-modal/editor-modal.component';

import { Layout } from '@models/dashboard';
import { SurveyService } from '@app/services/survey/survey.service';
import { SortablejsOptions } from 'ngx-sortablejs';
import { faLoveseat } from '@fortawesome/pro-duotone-svg-icons';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardComponent implements OnInit {
  identifier: string;
  layout: Layout;
  displayPublishBar = false;

  unlocked = false;
  password: string;

  questions$;
  tile: Tile;

  loading = {
    page: true,
    save: false
  };

  view = 'charts';

  error = {
    dashboard: '',
    tile: this.tile,
    password: ''
  };

  constructor(
    private route: ActivatedRoute,
    private simpleModalService: SimpleModalService,
    public dashboardService: DashboardService,
    public surveyService: SurveyService,
    public authService: AuthService
  ) {
    this.route.params.subscribe((params) => {
      this.identifier = params.identifier;
    });
  }

  demographicsOptions: SortablejsOptions = {
    onUpdate: () => {
      this.dashboardService.saveSelectedDashboard();
    }
  };

  ngOnInit() {
    this.unlockDashboard('');
  }

  unlockDashboard(password: string) {
    this.loading.page = true;
    this.error.dashboard = '';
    this.error.password = '';
    this.authService.isAuthenticated$.subscribe((loggedIn) => {
      if (loggedIn && this.identifier.length !== 32) {
        this.dashboardService.getByAccessCode(this.identifier, this.password).subscribe(
          (dashboard) => {
            this.dashboardService.setDashboard(dashboard);
            this.dashboardService.editing = true;
            this.unlocked = true;

            if (this.dashboardService.selectedDashboard.layouts.draft) {
              this.layout = this.dashboardService.selectedDashboard.layouts.draft;
            } else {
              // this.layout = new Layout();
            }

            this.surveyService.questions.subscribe((x) => {
              this.questions$ = x;
            });
            this.surveyService.fetchQuestions(this.dashboardService.getCurrentSurveyAccessCode());

            this.dashboardService.editing = true;

            if (this.dashboardHasUnpublishedChanges()) {
              this.displayPublishBar = true;
            }
            this.loading.page = false;
          },
          (error) => {
            this.handlePasswordError(error);
          }
        );
      } else {
        this.dashboardService.getByAccessCode(this.identifier, this.password).subscribe(
          (dashboard) => {
            this.dashboardService.setDashboard(dashboard);
            this.layout = this.dashboardService.selectedDashboard.layouts.draft;
            this.loading.page = false;
            this.unlocked = true;
            // this.editing = false;
          },
          (error) => {
            this.handlePasswordError(error);
          }
        );
      }
    });
  }

  handlePasswordError(error) {
    this.loading.page = false;
    if (error.status === 401) {
      // just requires a password so no need to handle
    } else if (error.status === 403) {
      this.error.password = error.error;
    } else {
      this.error.dashboard =
        'Error accessing dashboard data. Please try again. If the problem persists, please contact support.';
    }
  }

  save(data?) {
    // const d = data;
    // if (data) { console.log('SAVE: ', data); }
    // data.loader = true;
    if (!this.loading.page && !this.loading.save) {
      this.loading.save = true;
      this.dashboardService.update().subscribe(
        (dashboard) => {
          if (dashboard) {
            this.dashboardService.setDashboard(dashboard);
            if (this.dashboardService.selectedDashboard.layouts.draft) {
              this.layout = this.dashboardService.selectedDashboard.layouts.draft;
            } else {
              this.layout = this.dashboardService.selectedDashboard.layouts.published;
            }

            // this.layout.find(t => t.id === data.tile.id).editing[data.loader] = false;
          }

          if (data) {
            // console.log('SAVE 2: ', data);
            // data.tile.loading.forEach(e => {
            //   e = false;
            // });
            data.tile.editing[data.loader] = false;
            // console.log('SAVE 3: ', data);
          }
          this.loading.save = false;

          // d.tile.loading.forEach(e => {
          //   e = false;
          // });

          // data.loader = false;
          // else {
          //   this.error.dashboard =
          //     'Error while saving dashboard. Please try again.';
          // }
        },
        (error) => {
          this.error.dashboard = 'Error while saving dashboard. Please try again.';
          this.loading.save = false;
          // data.loader = false;
        }
      );
    }
  }

  publish() {
    this.loading.save = true;

    this.dashboardService.publish().subscribe(
      (dashboard) => {
        this.layout = dashboard.layouts.published;
        this.hidePublishBar();
        this.loading.save = false;
      },
      (error) => {
        this.error.dashboard = 'Error while publishing dashboard.';
      }
    );
  }

  // toggleEditMode() {
  //   this.editing = !this.editing;
  // }

  // to-do: not sure we need this any more
  // updateChart() {
  //   const disposable = this.simpleModalService
  //     .addModal(ChartPreviewComponent, {
  //       title: 'Preview',
  //       buttonText: 'Add Chart',
  //       surveyId: ''
  //     })
  //     .subscribe(isConfirmed => {
  //       if (isConfirmed) {
  //       }
  //     });
  // }

  setEditTile(tile: Tile) {}

  launchEditor(tile) {
    const disposable = this.simpleModalService
      .addModal(EditorModalComponent, {
        title: 'Share',
        tile: tile,
        buttonText: 'Add Chart'
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
        }
      });
  }

  edit(tile) {
    this.launchEditor(tile);
  }

  closeEditor() {
    this.tile = null;
  }

  private hidePublishBar() {
    $('#status-bar').slideUp('fast');
  }

  private showPublishBar() {
    if (!$('#status-bar').is(':visible')) {
      $('#status-bar').hide().slideDown('fast');
    }
  }

  // to-do: probably need to move this logic to the dashboard service
  private dashboardHasUnpublishedChanges() {
    // if (this.dashboard.layouts.published !== this.dashboard.layouts.draft) {
    return true;
    // }
    // return false;
  }
}
