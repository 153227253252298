import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '@env/environment';
import { Client } from '@app/models/dashboard';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public clients = new ReplaySubject<Client[]>(1);

  constructor(private http: HttpClient) {}

  fetchAll() {
    this.http.get<Client[]>(environment.apiUrl + '/admin/clients').subscribe(clients => {
      this.clients.next(clients);
    });
  }

  create(dashboard): Observable<Client> {
    return this.http.post<Client>(environment.apiUrl + '/admin/clients', dashboard);
  }

  update(client): Observable<Client> {
    return this.http.put<Client>(environment.apiUrl + '/admin/clients', client);
  }

  delete(client) {
    return this.http.delete(environment.apiUrl + '/admin/clients/' + client.id);
  }
}
