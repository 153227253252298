import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';

import { environment } from 'environments/environment';

import { SummaryResponse, SummaryPayload, Question, Survey, DemographicPayload, DemographicResponse } from '../../models/dashboard';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  // would be good to implement a caching layer here?
  // private surveyQuestions: {[key: string] : {questions: Question[]}};

  questions = new ReplaySubject<Question[]>(1);

  constructor(private http: HttpClient) {}

  create(survey): Observable<Survey> {
    return this.http.post<Survey>(environment.apiUrl + '/surveys/', survey);
  }

  update(survey): Observable<Survey> {
    return this.http.put<Survey>(environment.apiUrl + '/surveys/' + survey.id, survey);
  }

  delete(survey) {
    return this.http.delete(environment.apiUrl + '/surveys/' + survey.id);
  }

  getAll() {
    return this.http.get<Survey[]>(environment.apiUrl + '/surveys').pipe(tap(results => results.sort((a, b) => a.title.localeCompare(b.title))));
  }

  getById(surveyId) {
    return this.http.get(environment.apiUrl + '/surveys/' + surveyId);
  }

  getQuestions(accessCode): Observable<Question[]> {
    return this.http
      .get<Question[]>(environment.apiUrl + '/surveys/' + accessCode + '/questions')
      .pipe(tap(results => results.sort((a, b) => a.title.localeCompare(b.title))));
  }

  fetchQuestions(accessCode) {
    this.http
      .get<Question[]>(environment.apiUrl + '/surveys/' + accessCode + '/questions')
      .pipe(tap(results => results.sort((a, b) => a.title.localeCompare(b.title))))
      .subscribe(x => this.questions.next(x));
  }

  getAnswers(questionId, surveyId: string) {
    const q = {
      id: 'abc',
      type: 'bar',
      config: {
        question: {
          id: questionId
        }
      }
    };
    return this.http.post(environment.apiUrl + '/tiledata/' + surveyId, q);
  }

  getLabelsAndDatasets(sources: any, surveyId: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/tiledata/' + surveyId, sources);
  }

  getDashboardSummary(payload: SummaryPayload, accessCode: string): Observable<SummaryResponse> {
    return this.http.post<SummaryResponse>(environment.apiUrl + '/summary/' + accessCode, payload);
  }

  getMycawTableData(payload: SummaryPayload, accessCode: string): Observable<SummaryResponse> {
    return this.http.post<SummaryResponse>(environment.apiUrl + '/mycaw/' + accessCode, payload);
  }

  getDemographicSummary(payload: DemographicPayload, accessCode: string): Observable<DemographicResponse> {
    return this.http.post<DemographicResponse>(environment.apiUrl + '/Demographics/' + accessCode, payload);
  }

  downloadQuestionLookup(){
    return this.http.get(environment.apiUrl + '/surveys/exportQuestionsLookup',
      { responseType: 'blob', headers: { Accept: 'text/csv' }});
  }

  downloadResponsesAsCSV(surveyId) {
    return this.http.get(environment.apiUrl + '/surveys/' + surveyId + '/export',
      { responseType: 'blob', headers: { Accept: 'text/csv' }});
  }

  downloadResponsesByRespondentAsCSV(surveyId, type) {
    return this.http.get(environment.apiUrl + '/surveys/' + surveyId + '/exportByRespondent?format=' + type,
      { responseType: 'blob', headers: { Accept: 'text/csv' }});
  }

  reloadSurveyData(surveyId) {
    return this.http.put(environment.apiUrl + '/admin/ReloadSurvey/' + surveyId,
    { });
  }

  uploadAsCSV(surveyId, file, type) {
    return this.http
    .post<any>(environment.apiUrl + '/surveys/' + surveyId + '/import/' + type, file, {
      reportProgress: true,
      observe: 'events'
    })
    .pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );

  }
}
