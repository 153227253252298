import { Question } from '@app/models/dashboard';

export module QuestionTitleHelper {
  export function filterQuestionTitles(questions: Question[]) {
    if (questions && questions.length > 0) {
      return questions.map(question => {
        question.title = this.getQuestionTitle(question);
        return question;
      });
    } else {
      return questions;
    }
  }

  export function getQuestionTitle(question: Question) {
    if (question) {
      const title = question.title.replace(/(\r\n|\n|\r)/gm, ' ');
      if (title.indexOf('<') > -1) {
        const firstRe = /\>([\w\-\s]+)(.+)/;
        var firstMatch = matchRegex(title, firstRe, 1);

        if (firstMatch.success && /\S/.test(firstMatch.text)) {
          return firstMatch.text;
        }

        const secondRe = /([\w\-\s]+)(<)/;
        var secondMatch = matchRegex(title, secondRe, 1);
        if (secondMatch.success && /\S/.test(secondMatch.text)) {
          return secondMatch.text;
        }
      }
      return question.title;
    }
    return 'No question';
  }

  export function matchRegex(text: string, regex: RegExp, group: number) {
    const matches = text.match(regex);
    let success = false;

    if (matches && matches.length > group && matches[group] && matches[group] !== '><') {
      success = true;

      return { text: matches[group], success: success };
    }
    return { text: text, success: success };
  }

  export function getQuestionOptionTitle(title: string) {
    const re = /([\w\-\s]+).(<.*\/>)/;
    const matches = title.match(re);

    if (matches && matches.length > 1 && matches[1] && matches[1] !== '><') {
      return matches[1];
    }
    return title;
  }
}
