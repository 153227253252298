import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Interceptors
import { AuthInterceptor } from '@auth/auth.interceptor';

// Auth
import { CallbackComponent } from '@auth/callback/callback.component';

// Material UI
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatButtonModule,
  MatProgressBarModule
} from '@angular/material';

import { AlertModule } from 'ngx-bootstrap';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

// Layout
import { GridsterModule } from 'angular-gridster2';

// Charts
import { ChartsModule } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import ChartAnnotationsPlugin from 'chartjs-plugin-annotation';
// import HorizonalLinePlugin from'chartjs-lines-plugin';

// Misc
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { SimpleModalModule } from 'ngx-simple-modal';
import { ClipboardModule } from 'ngx-clipboard';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxSortableModule } from 'ngx-sortable';

// Icons
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { faPaintBrush, faChartBar, faChartPie, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsAlt } from '@fortawesome/pro-regular-svg-icons';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle, faChevronRight, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faCloudDownloadAlt, faLock, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faAnalytics } from '@fortawesome/pro-solid-svg-icons';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { faAbacus, faComments } from '@fortawesome/pro-regular-svg-icons';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { faCheckSquare, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { faStar as fasStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as falStar } from '@fortawesome/pro-solid-svg-icons';

// Services
import { AuthService } from '@auth/auth.service';
import { DashboardService } from '@services/dashboard/dashboard.service';

// Pages
import { DashboardComponent } from './pages/dashboard/dashboard.page';
import { AdminComponent } from '@pages/admin/admin.page';
import { AdminSurveyComponent } from '@pages/admin-survey/admin-survey.component';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from '@app/components/header/header.component';

import { DashboardFiltersComponent } from '@components/dashboard-filters/dashboard-filters.component';
import { DashboardShareComponent } from '@components/dashboard-share/dashboard-share.component';

import { GridComponent } from '@components/grid/grid.component';

import { TileTextEditorComponent } from '@components/tiles/text/text.component.editor';
import { TileTextDisplayComponent } from '@components/tiles/text/text.component.display';
import { TileBarEditorComponent } from '@components/tiles/bar/bar.component.editor';
import { TileBarDisplayComponent } from '@components/tiles/bar/bar.component.display';
import { TileTrendEditorComponent } from '@components/tiles/trend/trend.component.editor';
import { TileTrendDisplayComponent } from '@components/tiles/trend/trend.component.display';
import { TileCountDisplayComponent } from '@components/tiles/count/count.component.display';
import { TileTimelineDisplayComponent } from '@components/tiles/timeline/timeline.component.display';
import { TileTimelineEditorComponent } from '@components/tiles/timeline/timeline.component.editor';
import { TilePieDisplayComponent } from '@components/tiles/pie/pie.component.display';
import { TilePieEditorComponent } from '@components/tiles/pie/pie.component.editor';
import { TileLineDisplayComponent } from '@components/tiles/line/line.component.display';
import { TileLineEditorComponent } from '@components/tiles/line/line.component.editor';
import { TileResponsesDisplayComponent } from '@components/tiles/responses/responses.component.display';
import { TileResponsesEditorComponent } from '@components/tiles/responses/responses.component.editor';
import { ColourPaletteComponent } from '@components/colour-palette/colour-palette.component';
import { DashboardAdminModalComponent } from '@components/dashboard-admin-modal/dashboard-admin-modal.component';
import { FilterComponent } from '@components/filter/filter.component';
import { SourcePickerComponent } from '@components/source-picker/source-picker.component';
import { SummaryComponent } from '@components/tiles/summary/summary.component';
import { EditorModalComponent } from '@components/editor-modal/editor-modal.component';
import { SourcesComponent } from '@components/sources/sources.component';
import { DemographicsComponent } from '@components/demographics/demographics.component';
import { AdminClientComponent } from '@pages/admin-client/admin-client.component';
import { ImageUploadComponent } from '@components/image-upload/image-upload.component';
import { MycawComponent } from '@components/mycaw/mycaw.component';
import { AdminFilterComponent } from '@components/admin-filter/admin-filter.component';
import { FilterPipe } from '@pipes/filter/filter.pipe';
import { AdminSurveysComponent } from '@pages/admin-surveys/admin-surveys.component';
import { SurveyAdminModalComponent } from '@components/survey-admin-modal/survey-admin-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    DashboardComponent,
    DashboardFiltersComponent,
    DashboardShareComponent,
    HeaderComponent,
    AdminComponent,
    GridComponent,
    TileTextEditorComponent,
    TileTextDisplayComponent,
    TileBarEditorComponent,
    TileBarDisplayComponent,
    TileCountDisplayComponent,
    TileTimelineDisplayComponent,
    TileTimelineEditorComponent,
    TilePieDisplayComponent,
    TilePieEditorComponent,
    TileLineDisplayComponent,
    TileLineEditorComponent,
    TileResponsesDisplayComponent,
    TileResponsesEditorComponent,
    ColourPaletteComponent,
    DashboardAdminModalComponent,
    FilterComponent,
    SourcePickerComponent,
    SummaryComponent,
    EditorModalComponent,
    SourcesComponent,
    DemographicsComponent,
    AdminClientComponent,
    ImageUploadComponent,
    MycawComponent,
    AdminFilterComponent,
    FilterPipe,
    TileTrendEditorComponent,
    TileTrendDisplayComponent,
    AdminSurveyComponent,
    AdminSurveysComponent,
    SurveyAdminModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    GridsterModule,
    SimpleModalModule,
    ClipboardModule,
    FontAwesomeModule,
    CKEditorModule,
    ChartsModule,
    AlertModule.forRoot(),
    ColorPickerModule,
    NgSelectModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgxSortableModule,
    // NgxDaterangepickerMd.forRoot(),
    DateRangePickerModule
  ],
  entryComponents: [
    DashboardShareComponent,
    EditorModalComponent,
    DashboardAdminModalComponent,
    SurveyAdminModalComponent
  ],
  providers: [
    AuthService,
    DashboardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(
      falStar,
      faChevronRight,
      faChevronLeft,
      faComments,
      faPencil,
      faSearch,
      faCheckSquare,
      faMinus,
      fasStar,
      faSquare,
      faLink,
      faClock,
      faUsers,
      faPaintBrush,
      faCalendarAlt,
      faPlus,
      faArrowsAlt,
      faCog,
      faCheck,
      faTimes,
      faTimesCircle,
      faCloudDownloadAlt,
      faAnalytics,
      faChartLine,
      faChartBar,
      faChartPie,
      faAbacus,
      faEdit,
      faLock
    );
  }
}
