import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question, ChartConfig } from '@models/dashboard';

// import { SortablejsOptions } from 'ngx-sortablejs';
import { SurveyService } from '@services/survey/survey.service';
import { DashboardService } from '@app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss']
})
export class SourcesComponent implements OnInit {
  @Input() groupBy: boolean;
  @Input() config: ChartConfig;
  @Input() filter: string;

  @Output() update = new EventEmitter<Question>();

  public questions: Question[];
  public sources: Question[];
  public selectedSource;

  // options: SortablejsOptions;
  aggregateRename = false;

  listStyle = {
    height: 'auto',
    width: '100%',
    dropZoneHeight: '65px'
  };

  loading = {
    aggregate: false
  };
  constructor(private surveyService: SurveyService, private dashboardService: DashboardService) {
    // this.options = {
    //   onUpdate: (event: any) => {
    //     console.log('UPDATED ORDER!');
    //   }
    // };
  }

  ngOnInit() {
    this.surveyService.questions.subscribe(x => {
      this.questions = x;
    });
  }

  addSource(source) {
    if (source && this.config.sources) {
      this.config.sources.push(source);
      this.selectedSource = null;
      this.update.emit();
    }
  }

  setGroupBy(source) {
      this.config.groupBy = source;
      this.update.emit();
  }

  compareFn(question1: Question, question2: Question): boolean {
    if (question2) {
      return question1.id === question2.id;
    }
  }

  sourceSearchFn(term: string, item: Question) {
    term = term.toLowerCase();
    if (item.alias) {
      return item.title.toLowerCase().indexOf(term) > -1 || item.alias.toLowerCase().indexOf(term) > -1;
    } else {
      return item.title.toLowerCase().indexOf(term) > -1;
    }
  }

  aggregateSave() {
    this.update.emit();
    this.aggregateRename = false;
  }

  renameSave(source) {
    this.update.emit();
    source.rename = false;
  }

  removeSource(source) {
    this.config.sources = this.config.sources.filter(s => s !== source);
    this.update.emit();
  }

  clearGroupBy() {
    this.config.groupBy = null;
    this.update.emit();
  }

  listSorted(source) {
    this.update.emit();
  }
}
