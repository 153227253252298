import { Component, OnInit, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Tile, Dashboard, Filter, DateRange } from '@app/models/dashboard';
import { DashboardService } from '@app/services/dashboard/dashboard.service';
import { SurveyService } from '@app/services/survey/survey.service';

@Component({
  selector: 'app-tile-count-display',
  templateUrl: './count.component.display.html',
  styleUrls: ['./count.component.display.scss']
})
export class TileCountDisplayComponent implements OnInit, OnChanges {
  [x: string]: any;

  @Input() tile: Tile;
  @Input() filters: Filter[];
  @Output() loading: boolean;
  public count: 0;

  dateRange: DateRange;

  initializing: boolean;

  constructor(public dashboardService: DashboardService, private surveyService: SurveyService) {}

  ngOnInit() {
    this.initializing = true;
    this.dashboardService.dateRange.subscribe(range => {
      this.dateRange = range;
      this.render();
    });

    this.dashboardService.currentFilters.subscribe(filters => {
      this.render();
    });

    this.initializing = false;
    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.tile && !changes.tile.isFirstChange()) || (changes.filters && !changes.filters.isFirstChange())) {
      this.render();
    }
  }

  render() {
    if (!this.initializing) {
      this.loading = true;

      let selectedDateRange = null;

      if (this.dateRange && this.dateRange.startDate !== '' && this.dateRange.endDate !== '') {
        selectedDateRange = this.dateRange;
      }

      const payload = {
        type: 'count',
        config: {
          filters: this.dashboardService.getFilters(),
          dateRange: selectedDateRange
        }
      };

      this.surveyService.getLabelsAndDatasets(payload, this.dashboardService.getCurrentSurveyAccessCode()).subscribe(response => {
        this.count = response;
      });

      this.loading = false;
    }
  }
}
