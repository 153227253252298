import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { SurveyService } from '@services/survey/survey.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import _ from 'lodash';

import { SummaryResponse, DemographicResponse } from '@models/dashboard';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  constructor(private http: HttpClient, private surveyService: SurveyService) {}

  get() {
    return this.http.get(environment.apiUrl + '/charts');
  }

  preview(payload) {
    return this.http.post(environment.apiUrl + '/charts/preview', payload);
  }

  getChartData(payload, surveyId): Observable<any> {
    return new Observable(o => {
      this.surveyService.getLabelsAndDatasets(payload, surveyId).subscribe(
        response => {
          if (response) {
            const result = {
              labels: [],
              data: [],
              responseCount: response.responseCount
            };

            if (payload.config.groupBy) {
              if (
                (payload.config.sourcesGroupsOnly && !payload.config.isGroupedByQuestion) ||
                (!payload.config.sourcesGroupsOnly && payload.config.isGroupedByQuestion)
              ) {
                response.datasets.map((data, index) => {
                  result.labels[index] = data.label;
                });

                response.labels.map((label, i) => {
                  result.data[i] = { label, data: [] };

                  response.datasets.map((dataset, j) => {
                    result.data[i].data[j] = dataset.data[i];
                    result.data[i].responseCount = response.responseCounts[i];
                  });
                });
              } else {
                result.data = response.datasets;
                result.labels = response.labels;
              }
            } else {
              result.data = response.data;
              result.labels = response.labels;
            }

            o.next(result);
            o.complete();
          } else {
            o.complete();
          }
        },
        error => {
          console.log('getChartData error: ', error);
          o.complete();
        }
      );
    });
  }

  getRawData(payload, surveyId): Observable<any> {
    return new Observable(o => {
      this.surveyService.getLabelsAndDatasets(payload, surveyId).subscribe(
        response => {
          if (response) {
            o.next(response);
            o.complete();
          } else {
            o.complete();
          }
        },
        error => {
          console.log('getChartData error: ', error);
          o.complete();
        }
      );
    });
  }

  getSummaryData(payload, optionIndexes: number[], accessCode): Observable<SummaryResponse> {
    return this.surveyService.getDashboardSummary(payload, accessCode).pipe(
      map(data => {
        const newData = _.map(data, (item, i) => {
          item.data = item.data.filter((dataPoint, a) => {
            if ((optionIndexes.length === 0 && a < 2) || optionIndexes.indexOf(a) > -1) {
              return true;
            }
            return false;
          });
          return item;
        });
        return newData;
      })
    );
  }

  getMycawTableData(payload, accessCode): Observable<SummaryResponse> {
    return this.surveyService.getMycawTableData(payload, accessCode).pipe(
      map(data => {
        const newData = _.map(data, (item, i) => {
          item.data = item.data.filter((dataPoint, a) => {
            if (a < 2) {
              return true;
            }
            return false;
          });
          return item;
        });
        return newData;
      })
    );
  }

  getDemographicData(payload, accessCode): Observable<DemographicResponse> {
    return this.surveyService.getDemographicSummary(payload, accessCode).pipe(
      map(data => {
        return data;
      })
    );
  }
}
