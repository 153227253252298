import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-admin-filter',
  templateUrl: './admin-filter.component.html',
  styleUrls: ['./admin-filter.component.scss']
})
export class AdminFilterComponent {
  @Input() placeholder = 'Filter...';
  @Input() loading: boolean;
  @Output() update = new EventEmitter();

  filterValue: string;
  filterValueUpdate = new Subject<string>();

  constructor() {
    this.filterValueUpdate
      .pipe(debounceTime(0), distinctUntilChanged())
      .subscribe(value => {
        value ? (this.loading = true) : (this.loading = false);
        this.update.emit(value);
      });
  }
}
