// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.ro.daysix.co/api',
  // apiUrl: 'http://localhost:5000',
  assetUrl: 'https://dev.ro.daysix.co/staticfiles',
  // assetUrl: 'http://localhost:8080',
  apiKey: 'hi&Pece*m%WFSpG&VbyUuFVVBcD!DwmM7VZBg%g*ICOgHOv&@#my@&u3hfandNmIpIb4RWH$ruCw3fi8GTNx$Bee&5LMnCo9l8gO',
  auth0: {
    domain: 'r-outcomes.eu.auth0.com',
    client_id: 'hlbnX7bzgSX05XArUaTcqmqsNu3XGhoc',
    audience: 'https://r-outcomes.com/api'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
